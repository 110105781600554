import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import date from "date-and-time";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import remy from "./ags.png";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Kfunc from "../utill/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
// import NumberFormat from "react-number-format";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import { LineLogin } from "reactjs-line-login";
// import "reactjs-line-login/dist/index.css";
import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import * as yup from "yup";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useWait, Waiter } from "react-wait";
import "./styles.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from '@material-ui/core/Avatar';
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import AWS from "aws-sdk";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import useClipboard from "react-hook-clipboard";

const liff = window.liff;
let SignupSchema = yup.object().shape({
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  password: yup
    .string()
    .min(6, "Password is too short.")
    .max(20, "Password is too long.")
    .required("This field is required."),
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

var S3_BUCKET = "linebotkeep-file";
var REGION = "ap-southeast-1";

AWS.config.update({
  accessKeyId: "AKIATGVUYF4LQCOFDYNB",
  secretAccessKey: "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",
});

const myBucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export default function Signup() {
  // export const Signup = () => {
  const classes = useStyles();
  /////////////////////////////////BUTON////////////////////////////
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [password, setpassword] = useState("");
  const [paylode, setpaylode] = useState(false);
  const [phone, setphone] = useState(false);
  const [button, setbutton] = useState(true);
  const [Name, setName] = useState(false);
  const [FnameLname, setFnameLname] = useState(false);
  const [brance, setbrance] = useState("");
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [age, setAge] = React.useState("");
  const [Showdetail, setShowdetail] = useState(true);
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [myInput, setmyInput] = useState("");

  // const [values, setValues] = React.useState({
  //   textmask: "   -       ",
  //   numberformat: "1320",
  // });

  const [Pic, setPic] = useState(remy);
  const [ques, setques] = useState({
    ques1: ``,
    ques2: ``,
    ques3: ``,
    Id_ques: ``,
  });

  const [object, setobject] = useState({
    namefb: ``,
    ckupdate: ``,
    laststatus: ``,
    restartArray: ``,
  });

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });

  const [shwoText, setshwoText] = useState(
    `Drag'n'drop images, !เลือกได้ครั้งละ1ไฟล์เท่านั้น!`
  );
  const handleDrop = (acceptedFiles) => {
    Swal.fire({
      title: "รหัสผ่าน PDF",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      confirmButtonText: "ตกลง",
      showLoaderOnConfirm: true,
      preConfirm: async (login) => {
        // return fetch(`//api.github.com/users/${login}`)
        //   .then((response) => {
        //     if (!response.ok) {
        //       throw new Error(response.statusText);
        //     }
        //     setmyInput(`https://pghejob.duckdns.org/?path=pdfencryp`)

        //     return `true`;
        //   })
        //   .catch((error) => {
        //     setmyInput(`https://pghejob.duckdns.org/?path=pdfencryp`)
        //     return `false`;
        //   });
        // var ups3 = await uploadFile(selectedFile);
        var formdata = new FormData();
        formdata.append("file", acceptedFiles[0], acceptedFiles[0].name);
        formdata.append("folder", "PKGAUTOCALL2/0885256214");
        formdata.append("namefile", "karantest");
        formdata.append("bucket", "url2img-pdf");
        formdata.append("password", login);

        var requestOptions = {
          method: "POST",
          body: formdata,
          redirect: "follow",
        };

        return fetch(
          "https://agsdocker.duckdns.org/S3-Mid-Upload-PdfPass",
          requestOptions
        )
          .then((response) => response.text())
          .then((result2) => {
            var parsedData = JSON.parse(result2);
            console.log(parsedData[0]["s3link"]);
            setmyInput(`${parsedData[0]["s3link"]}`);

            return `${parsedData[0]["s3link"]}`;
          })
          .catch((error) => {
            console.log("error", error);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      const star = async () => {
        // await timeout(1500);
        if (result.value) {
          Swal.fire({
            title: `<strong>แปลงไฟล์เสร็จสิ้น</strong>`,
            icon: "success",
            html: "กดที่ปุ่ม  <b>COPY!</b>, ",
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "COPY!",
            denyButtonText: `Cancel`,
            preConfirm: (login) => {
              navigator.clipboard.writeText(result.value);
            },
            allowOutsideClick: () => !Swal.isLoading(),
          });
        } else {
          Swal.fire({
            title: `<strong><a href="//sweetalert2.github.io">https://pghejob.duckdns.org/?path=pdfencryp</a></strong>`,
            icon: "success",
            html: "กดที่ปุ่ม  <b>COPY!</b>, ",
            showCloseButton: true,
            showCancelButton: false,
            focusConfirm: false,
            confirmButtonText: "COPY!",
            denyButtonText: `Cancel`,
            preConfirm: (login) => {
              navigator.clipboard.writeText(myInput);
            },
            allowOutsideClick: () => !Swal.isLoading(),
          });
        }
      };
      star();
    });
    console.log(acceptedFiles);
    setshwoText(acceptedFiles[0].name);
    setSelectedFile(acceptedFiles[0]);
  };
  ////////////////////////////////////select-data///////////////////////
  const [MultiList, setMultiList] = useState({
    channel: [`ช่องทาง`, `กลุ่ม O`, `กลุ่ม P4`, `กลุ่ม MR`],
    service: [
      `งานบริการ`,
      `สมัครงาน`,
      `ถามยอดชำระค่างวด`,
      `ชำระค่างวด`,
      `จัดไฟแนนซ์`,
      `ทะเบียนประกัน พรบ.`,
      `ขอหมายเลขบัญชีธนาคาร`,
      `ค่าปรับผิดนัดชำระ`,
      `เลื่อนค่างวด`,
      `ขอเบอร์โทรสาขา`,
      `ฝากประกาศขาย`,
      `ชื่นชมการบริการ`,
      `ร้องเรียนการบริการ`,
      `ยื่นขอประมูลรถ`,
      `แจ้งเปลี่ยนข้อมูลส่วนตัว`,
      `การหยืดหยุ่นการชำระหนี้`,
      `ใบเสร็จ`,
      `สมัครตัวแทนการตลาด`,
      `อื่นๆ`,
    ],
    product: [`ผลิตภัณฑ์`, `รถจักรยานยนต์`, `รถยนต์`, `ที่ดิน`, `อื่นๆ`],
    custype: [`ประเภทลูกค้า`, `เก่า`, `ใหม่`],
    ques_sta: [`สถานะงาน`, `ตามต่อ`, `แล้วเสร็จ`, `ส่งเรื่องไปสาขา`],
    send_bu: [
      `NAY`,
      `TKK`,
      `TYK`,
      `SOD`,
      `SKL`,
      `WNY`,
      `BRM`,
      `SUR`,
      `KBB`,
      `PCL`,
      `CHS`,
      `NKY`,
      `SRB`,
      `WAM`,
      `LBR`,
      `NKR`,
      `PYJ`,
      `SNR`,
      `NTR`,
      `RBR`,
      `KAN`,
      `PTY`,
      `RYL`,
      `PLD`,
      `STH`,
      `NOK`,
      `BUK`,
      `UDD`,
      `MDH`,
      `SKN`,
    ],
  });
  const [channel, setchannel] = useState([
    `ช่องทาง     `,
    `กลุ่ม O`,
    `กลุ่ม P4`,
    `กลุ่ม MR`,
  ]);
  const [Showbrance, setShowbrance] = useState(true);
  const [CHchannel, setCHchannel] = useState("");
  const [date, setdate] = useState("");
  // const handleClipboardReadError = (error) => {
  //   console.log("There was an error reading from the clipboard:", error);
  // };
  // var [clipboard, copyToClipboard] = useClipboard({}, handleClipboardReadError);
  React.useEffect(() => {
    const Liffin = async () => {
      //LIFF START//
      setdate(moment().format("DD-MM-YYYY HH:mm:ss"));

      Swal.fire({
        title: "เข้าสู่ระบบ",
        html: `<input type="text" id="login" class="swal2-input" placeholder="รหัสพนักงาน">
       <input type="text" id="password" class="swal2-input" placeholder="วันเดือนปีเกิด ตัวอย่าง 02061994">`,
        confirmButtonText: "Login",
        preConfirm: async () => {
          const login = Swal.getPopup().querySelector("#login").value;
          const password = Swal.getPopup().querySelector("#password").value;

          let TS = `${password.substring(4, 8)}-${password.substring(
            2,
            4
          )}-${password.substring(0, 2)}`;
          // console.log(TS);
          let command2 = `SELECT * FROM PKGemployee WHERE id LIKE '${login}' AND birthDay LIKE '${TS}'`;
          var jsondata1 = {
            database: "PPP7",
            command: command2,
            path: "sqlcommand",
            port: "5003",
          };
          const MYSQL = await Kfunc.fetchlocal(jsondata1);
          console.log(MYSQL);
          if (MYSQL.length > 0) {
            return "true";
          } else {
            Swal.showValidationMessage(`รหัสผ่านผิด`);
          }
        },
      }).then((result) => {
        if (result.value == `true`) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: "เข้าสู่ระบบเรียบร้อย",
            showConfirmButton: false,
            timer: 1500,
          });
          setbutton(false);
        } else {
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "รหัสผ่านผิิด กรุนาลองใหม่",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      });
    };
    Liffin();
  }, []);

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const params = {
        ACL: "public-read",
        Body: file,
        Bucket: S3_BUCKET,
        Key: `login/${object.namefb}.json`,
        ContentType: selectedFile.type,
      };

      myBucket
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          setProgress(Math.round((evt.loaded / evt.total) * 100));
          if (Math.round((evt.loaded / evt.total) * 100) == 100) {
            setshwoText(`อัปโหลดสำเร็จ`);
            return resolve(true);
          } else {
            setshwoText(
              `upload ${Math.round((evt.loaded / evt.total) * 100)}%`
            );
          }
        })
        .send((err) => {
          if (err) {
            console.log(err);
            setshwoText(`อัปโหลด Fail`);
            return resolve(false);
          }
        });
    });
  };

  const handleButtonClick2 = async () => {
    // var database = "BCT_AMS2_RPLC";
    // var table = "whatapp_token";
    // var query = { key: "token", query: "karantoekn" };
    var jsondata = {
      database: "PPP7",
      table: "PKGemployee",
      query: { key: "id", query: phone },
    };
    const check = await Kfunc.databass(jsondata);
    console.log(check);
    try {
      if (check[0].id) {
        console.log("ssssssssss");
        // alert(`${check[0].thprefix} ${check[0].name_th} ${check[0].surname_th}`)
        setFnameLname(
          `${check[0].thprefix} ${check[0].name_th} ${check[0].surname_th}`
        );
        setbrance(check[0].company_ctt);
        setpassword(false);
        setpaylode(check[0]);
        setbutton(false);
        // alert(check[0].line_id);
      }
    } catch (error) {
      setpassword(true);
      console.log("xxxxxxxxxxx");
    }
  };

  const handleButtonClick = async () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);
      timer.current = window.setTimeout(() => {
        setSuccess(true);
        setLoading(false);
      }, 2000);
    }
  };

  var querystring = _interopDefault(require("querystring"));

  const select = (e) => {
    var jspare = JSON.parse(e.target.value);
    setobject({
      ...object,
      namefb: jspare.namefb,
      restartArray: JSON.parse(jspare.restartArray),
    });
    setPic(jspare.img);
    setques({
      ...ques,
      ques1: jspare.ckupdate,
    });
    // console.log(JSON.parse(jspare.restartArray))
    // alert(event.target.value.ques1)
  };

  function Spinner() {
    return (
      <img
        className="spinner"
        width="24"
        height="24"
        src="https://i.pinimg.com/originals/3e/f0/e6/3ef0e69f3c889c1307330c36a501eb12.gif"
      />
    );
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function Basic(props) {
    return (
      <Dropzone
        onDrop={handleDrop}
        // accept="image/*"
        // minSize={1024}
        // maxSize={3072000}
      >
        {({
          getRootProps,
          getInputProps,
          isDragActive,
          isDragAccept,
          isDragReject,
        }) => {
          const additionalClass = isDragAccept
            ? "accept"
            : isDragReject
            ? "reject"
            : "";

          return (
            <div
              {...getRootProps({
                className: `dropzone ${additionalClass}`,
              })}
            >
              <input {...getInputProps()} />
              <span>{isDragActive ? "📂" : "📁"}</span>
              <p>{shwoText}</p>
            </div>
          );
        }}
      </Dropzone>
    );
  }

  function UserCreateButton() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={buttonClassname}
        // disabled={loading}
        onClick={() => {
          var fun = async () => {
            await startWaiting("creating user");
            if (object.namefb == ``) {
              alert(`กรอกเลือกfacebook`);
              endWaiting("creating user");
            } else {
              var ups3 = await uploadFile(selectedFile);
              console.log(ups3);
              if (ups3 == false) {
                alert(`ไม่สามารถ upload S3ได้`);
                endWaiting("creating user");
              } else {
                for (const item of object.restartArray) {
                  console.log(item);
                  var restart = {
                    nameCon: item,
                    path: "restartDocker2",
                    port: "5200",
                  };
                  const RESTA = await Kfunc.fetchlocal(restart);
                  if (RESTA.status == false) {
                    alert(`รีสตาร์ท ${item} ไม่สำเร็จ`);
                  }
                }

                // object.path = "endjob";
                // object.port = "5004";
                // /////////////////Mysql

                let newvalues = [
                  { key: "ckupdate", newvalues: object.ckupdate },
                ];
                var jsondata1 = {
                  database: "CI_Docker_MessengerInstance",
                  table: "LoginRestart",
                  query: { key: "namefb", query: object.namefb },
                  newvalues: newvalues,
                  path: "updater",
                  port: "5003",
                };
                const MYSQL = await Kfunc.fetchlocal(jsondata1);
                if (MYSQL.status == "UpdateSuccess") {
                  alert(`login สำเร็จ`);
                  endWaiting("creating user");
                  window.location.reload();
                } else {
                  alert(`ลองใหม่`);
                  endWaiting("creating user");
                }
              }
            }
          };
          fun();
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ตกลง
        </Wait>
      </Button>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar alt="Remy Sharp" src={Pic} className={classes.large} />
        {/* <Typography component="h1" variant="h5">
          ระบบบันทึกงานบริการ
        </Typography> */}
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="ระบบ ป้องกันPDF" secondary={date} />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              {button === false ? (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Basic />
                  </Grid>
                </Grid>
              ) : null}
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
