import React, { useState } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import date from "date-and-time";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import remy from "./ags.png";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Kfunc from "../utill/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
// import NumberFormat from "react-number-format";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import { LineLogin } from "reactjs-line-login";
// import "reactjs-line-login/dist/index.css";
// import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import * as yup from "yup";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useWait, Waiter } from "react-wait";
import "./styles.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from '@material-ui/core/Avatar';
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import { ReactSession } from "react-client-session";
import TelegramLoginButton from "react-telegram-login";
// ReactSession.setStoreType("localStorage");
const liff = window.liff;
let SignupSchema = yup.object().shape({
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  password: yup
    .string()
    .min(6, "Password is too short.")
    .max(20, "Password is too long.")
    .required("This field is required."),
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
export default function Signup() {
  // export const Signup = () => {
  const classes = useStyles();
  /////////////////////////////////BUTON////////////////////////////
  const [loading, setLoading] = React.useState(false);
  const [loginbutton, setloginbutton] = useState(false);
  const [success, setSuccess] = React.useState(false);
  const [password, setpassword] = useState("");
  const [paylode, setpaylode] = useState(false);
  const [phone, setphone] = useState(false);
  const [button, setbutton] = useState(true);
  const [Name, setName] = useState(false);
  const [FnameLname, setFnameLname] = useState(false);
  const [brance, setbrance] = useState("");
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [age, setAge] = React.useState("");
  const [Showdetail, setShowdetail] = useState(true);
  // const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  // var { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  const [values, setValues] = React.useState({
    textmask: "   -       ",
    numberformat: "1320",
  });

  const [Pic, setPic] = useState(remy);
  const [ques, setques] = useState({
    ques1: ``,
    ques2: ``,
    ques3: ``,
    Id_ques: ``,
  });

  const [object, setobject] = useState({
    Id_ques: ``,
    group_id: ``,
    channel: ``,
    service: ``,
    product: ``,
    custype: ``,
    ques_sta: ``,
    bu: ``,
    admin: ``,
    B: `X`,
    send_bu: ``,
    detail: ``,
  });

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  ////////////////////////////////////select-data///////////////////////
  const [MultiList, setMultiList] = useState({
    channel: [`ช่องทาง`, `กลุ่ม O`, `กลุ่ม P4`, `กลุ่ม MR`],
    service: [
      `งานบริการ`,
      `health(สุขภาพ)`,
      `wealth(ความมั่นคั่ง)`,
      `wellbeing(ความเป็นอยู่)`,
      `time(ความสมดุลย์)`,
      `fun(ความสนุกสนาน)`,
      `สรรหา`,
      `ประเมิน`,
      `พัฒนา`,
      `สวัสดิการ`,
      `TA`,
      `MS24`,
      `บัตรสมาชิก`,
      `ใบลา`,
      `สหกรณ์`,
      `GTC`,
      `โอนย้าย`,
      `เงินเดือน`,
      `อื่นๆ`,
    ],
    product: [`ผลิตภัณฑ์`, `รถจักรยานยนต์`, `รถยนต์`, `ที่ดิน`, `อื่นๆ`],
    custype: [`ประเภทลูกค้า`, `เก่า`, `ใหม่`],
    ques_sta: [
      `สถานะงาน`,
      `ติดตามงานต่อ`,
      `จ่ายงานMS`,
      `ไม่ต้องบันทึกประเด็น`,
      `ดำเนินการแล้วเสร็จ`,
    ],
    send_bu: [
      `NAY`,
      `TKK`,
      `TYK`,
      `SOD`,
      `SKL`,
      `WNY`,
      `BRM`,
      `SUR`,
      `KBB`,
      `PCL`,
      `CHS`,
      `NKY`,
      `SRB`,
      `WAM`,
      `LBR`,
      `NKR`,
      `PYJ`,
      `SNR`,
      `NTR`,
      `RBR`,
      `KAN`,
      `PTY`,
      `RYL`,
      `PLD`,
      `STH`,
      `NOK`,
      `BUK`,
      `UDD`,
      `MDH`,
      `SKN`,
    ],
  });
  const [channel, setchannel] = useState([
    `ช่องทาง     `,
    `กลุ่ม O`,
    `กลุ่ม P4`,
    `กลุ่ม MR`,
  ]);
  const [Showbrance, setShowbrance] = useState(true);
  const [CHchannel, setCHchannel] = useState("");
  const [date, setdate] = useState("");
  const [botname, setbotname] = useState("");
  React.useEffect(() => {
    if (window.location.hostname === `pghejob.prachakij.com`) {
      setbotname(`karan509_bot`);
      setSuccess(true);
    }
    if (window.location.hostname === `pghejob.duckdns.org`) {
      setbotname(`KaranTestDev_bot`);
      setSuccess(true);
    }
    ReactSession.setStoreType("localStorage");
    var logintele = ReactSession.get("id_telegram");

    const Liffin = async () => {
      var url = _interopDefault(require("url"));
      var urlParts = url.parse(window.location.href, true);
      var query = urlParts.query;
      setbrance(query.groupid);
      setobject({ ...object, group_id: query.groupid, bu: query.groupid });

      if (logintele) {
        setdate(moment().format("DD-MM-YYYY HH:mm:ss"));
        var queryservice = {
          database: "CI_Docker_Social_Line",
          table: "type_pa",
          path: "finder",
          port: "5003",
        };
        const Qser = await Kfunc.fetchlocal(queryservice);

        var arraypush = [];
        for (let index = 0; index < Qser.length; index++) {
          const element = Qser[index];
          arraypush.push(element.name);
        }
        console.log(arraypush);
        arraypush.unshift(`งานบริการ`);
        setMultiList({ ...MultiList, service: arraypush });
        // const MYSQL = await Kfunc.fetchlocal(jsondata1);
        // console.log(MYSQL);
        // if (MYSQL.length > 0) {
        setobject({ ...object, admin: ReactSession.get("emp_id") });
        // alert("OK");
        var jsondata2 = {
          database: "CI_Docker_telegram",
          table: "Ques_telegram_pgh",
          query: { key: "group_id", query: query.groupid },
          where: { key: "check_react", query: "false" },
          path: "finderwhere",
          port: "5003",
        };
        // jsondata1.table = "Ques_Pgh_out";
        // jsondata1.query.key = "group_id";
        // jsondata1.query.query = brance;
        // jsondata1.where.query = "false";
        const checkgroup = await Kfunc.fetchlocal(jsondata2);
        console.log(checkgroup);
        if (checkgroup.length > 0) {
          // alert("OK");
          console.log("checkgroup........");
          console.log(checkgroup);
          checkgroup.unshift({
            Id_ques: "เลือก",
            ques1: "ไม่มี",
            ques2: "ไม่มี",
            ques3: "ไม่มี",
          });
          setpaylode(checkgroup);
          setloginbutton(true);
          // setques({
          //   ...ques,
          //   ques1: checkgroup[0].ques1,
          //   ques2: checkgroup[0].ques2,
          //   ques3: checkgroup[0].ques3,
          // });
          setbutton(false);
        } else {
          setloginbutton(false);
          alert("ไม่มีidกลุ่มในระบบ");
        }
        // } else {
        //   alert("กรุณาสมัครadmin");
        // }
      }else{ 
        setloginbutton(true);
      }
    };
    Liffin();
  }, []);

  const handleTelegramResponse = (response) => {
    console.log(response);
    (async () => {
      if (response.id) {
        // console.log(response.id);
        var queryservice = {
          database: "CI_Docker_telegram",
          table: "admin",
          query: { key: "telegram_id", query: response.id },
          path: "finder",
          port: "5003",
        };
        const Qser = await Kfunc.fetchlocal(queryservice);
        if (Qser.length > 0) {
          ReactSession.set("id_telegram", response.id);
          ReactSession.set("emp_id", Qser[0].emp_id);
          window.location.reload();
        } else {
          alert(`ไม่อยู่ในระบบ admin`);
        }
      }
    })();
  };

  const handleChangePH = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  var querystring = _interopDefault(require("querystring"));
  // var linesummit = async () => {
  //   if (
  //     object.Id_ques == "" ||
  //     // object.channel == `` ||
  //     object.service == `` ||
  //     // object.product == `` ||
  //     // object.custype == `` ||
  //     object.ques_sta == ``
  //   ) {
  //     alert(`กรอกข้อมูลให้ครับ`);
  //     return;
  //   } else {
  //     object.path = "endjob";
  //     object.port = "5031";
  //     object.custype = "pgh";
  //     /////////////////Mysql
  //     var ejob = "true";
  //     if (object.ques_sta == "ติดตามงานต่อ") {
  //       ejob = "false";
  //     }
  //     let newvalues = [
  //       { key: "state", newvalues: object.ques_sta },
  //       { key: "serve", newvalues: object.service },
  //       { key: "admin", newvalues: object.admin },
  //       { key: "details", newvalues: object.detail },
  //       { key: "check_react", newvalues: ejob },
  //     ];
  //     var jsondata1 = {
  //       database: "CI_Docker_Social_Line",
  //       table: "Ques_Pgh",
  //       query: { key: "Id_ques", query: object.Id_ques },
  //       newvalues: newvalues,
  //       path: "updater",
  //       port: "5003",
  //     };
  //     const MYSQL = await Kfunc.fetchlocal(jsondata1);
  //     const UPDATE = await Kfunc.fetchlocal(object);
  //     console.log(UPDATE);
  //     // alert(JSON.stringify(UPDATE));
  //     if (UPDATE.update == true) {
  //       alert(`update true`);
  //       window.location.reload();
  //     } else {
  //       alert(`update false`);
  //       return;
  //     }
  //   }
  // };

  const select = (e) => {
    var jspare = JSON.parse(e.target.value);
    setobject({
      ...object,
      Id_ques: jspare.Id_ques,
      bu: jspare.bu,
    });
    setques({
      ...ques,
      ques1: jspare.ques1,
      ques2: jspare.ques2,
      ques3: jspare.ques3,
    });
    console.log(jspare.ques1);
    // alert(event.target.value.ques1)
  };

  function Spinner() {
    return (
      <img
        className="spinner"
        width="24"
        height="24"
        src="https://i.pinimg.com/originals/3e/f0/e6/3ef0e69f3c889c1307330c36a501eb12.gif"
      />
    );
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function UserCreateButton() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={buttonClassname}
        // disabled={loading}
        onClick={() => {
          var fun = async () => {
            await startWaiting("creating user");
            console.log("Detail");
            console.log(object);
            if (
              object.Id_ques == "" ||
              // object.channel == `` ||
              object.service == `` ||
              // object.product == `` ||
              // object.custype == `` ||
              object.ques_sta == ``
            ) {
              alert(`กรอกข้อมูลให้ครับ`);
              endWaiting("creating user");
            } else {
              object.path = "LiffBCT";
              object.port = "5036";
              object.custype = "pgh";
              /////////////////Mysql
              var ejob = "true";
              if (object.ques_sta == "ติดตามงานต่อ") {
                ejob = "false";
              }
              let newvalues3 = [
                { key: "state", newvalues: object.ques_sta },
                { key: "serve", newvalues: object.service },
                { key: "admin", newvalues: object.admin },
                { key: "details", newvalues: object.detail },
                { key: "check_react", newvalues: ejob },
              ];

              var jsondata3 = {
                database: "CI_Docker_telegram",
                table: "Ques_telegram_pgh",
                query: { key: "Id_ques", query: object.Id_ques },
                newvalues: newvalues3,
                path: "updater",
                port: "5003",
              };
              const MYSQL = await Kfunc.fetchlocal(jsondata3);
              // await timeout(3000);
              const UPDATE = await Kfunc.fetchlocal(object);
              console.log(MYSQL);
              console.log(UPDATE);

              // alert(JSON.stringify(MYSQL));
              if (UPDATE.updated == true) {
                alert(`บันทึกสำเร็จ`);
                endWaiting("creating user");
                window.location.reload();
              } else if (UPDATE.error) {
                alert(`ไม่เจอแถวใน BCT`);
                endWaiting("creating user");
              } else {
                alert(`ผิดพลาด`);
                endWaiting("creating user");
              }
            }
          };
          fun();
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ตกลง
        </Wait>
      </Button>
    );
  }

  function UserCreateButton2() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    return (
      <Button
        type="submit"
        variant="contained"
        color="red"
        // disabled={loading}
        onClick={() => {
          ReactSession.set("id_telegram", null);
          window.location.reload();
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ออกจากระบบ
        </Wait>
      </Button>
    );
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar alt="Remy Sharp" src={Pic} className={classes.large} />
        {/* <Typography component="h1" variant="h5">
          ระบบบันทึกงานบริการ
        </Typography> */}
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <WorkIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="ระบบบันทึกงานบริการ PGH GROUP"
              secondary={date}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
        {/* <ValidationTextField
          className={classes.margin}
          required
          component="h1"
          // disabled
          id="filled-disabled"
          variant="h5"
          variant="outlined"
          defaultValue="ระบบบันทึกงานบริการ"
          id="validation-outlined-input"
        /> */}
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  {button === false ? (
                    <FormControl className={classes.margin} fullWidth>
                      <InputLabel htmlFor="demo-customized-select-native">
                        คำถามลูกค้า
                      </InputLabel>
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={ques.Id_ques}
                        onChange={select}
                        fullWidth
                        input={<BootstrapInput />}
                      >
                        {/* <option aria-label="None" value="" />
                      <option value={10}>Ten</option>
                      <option value={20}>Twenty</option>
                      <option value={30}>Thirกกกกกกกกกกกกกกกกกty</option> */}
                        {paylode.map((v) => (
                          <option
                            key={v.Id_ques}
                            name={v.Id_ques}
                            value={JSON.stringify(v)}
                          >
                            {v.create_time} {v.Id_ques.substring(0, 7)}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {button === false ? (
                    <TextField
                      disabled
                      id="filled-disabled"
                      label="คำถาม1"
                      value={ques.ques1}
                      variant="filled"
                      fullWidth
                      name="number"
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {button === false ? (
                    <TextField
                      disabled
                      id="filled-disabled"
                      label="คำถาม2"
                      value={ques.ques2}
                      variant="filled"
                      fullWidth
                      name="number"
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {button === false ? (
                    <TextField
                      disabled
                      id="filled-disabled"
                      label="คำถาม3"
                      value={ques.ques3}
                      variant="filled"
                      fullWidth
                      name="number"
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {button === false ? (
                    <FormControl className={classes.margin} fullWidth>
                      {/* <InputLabel htmlFor="demo-customized-select-native">
                      คำถามลูกค้า
                    </InputLabel> */}
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={CHchannel}
                        onChange={(e) => {
                          setobject({
                            ...object,
                            service: e.target.value,
                          });
                        }}
                        fullWidth
                        input={<BootstrapInput />}
                      >
                        {MultiList.service.map((v) => (
                          <option key={v} name={v} value={v}>
                            {v}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {button === false ? (
                    <FormControl className={classes.margin} fullWidth>
                      {/* <InputLabel htmlFor="demo-customized-select-native">
                      คำถามลูกค้า
                    </InputLabel> */}
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={CHchannel}
                        onChange={(e) => {
                          setobject({
                            ...object,
                            ques_sta: e.target.value,
                          });
                        }}
                        fullWidth
                        input={<BootstrapInput />}
                      >
                        {MultiList.ques_sta.map((v) => (
                          <option key={v} name={v} value={v}>
                            {v}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  {button === false ? (
                    <TextField
                      // error={phone === true}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        e.preventDefault();
                        // s(e.target.value);
                        setobject({ ...object, detail: e.target.value });
                      }}
                      // name="number"
                      label="หมายเหตุ"
                      // type="number"
                      id="outlined-error-helper-text"
                    />
                  ) : null}
                  {/* ) : null} */}
                </Grid>
              </Grid>
              {button === false ? (
                <div className={classes.wrapper}>
                  <Waiter>
                    <UserCreateButton /> <UserCreateButton2 />
                  </Waiter>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              ) : null}

              <div className={classes.wrapper}>
                {button === true && success === true && loginbutton === true ? (
                  <TelegramLoginButton
                    dataOnauth={handleTelegramResponse}
                    botName={botname}
                  />
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
