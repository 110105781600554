import React, { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import "./otp.css";
import { Animate, AnimateGroup } from "react-simple-animate";
import { divStyle, buttonStyle } from "./styles";
import { useLocation } from "react-router-dom";
import firebase from "./firebase";
import { getAuth, RecaptchaVerifier } from "firebase/auth";
import Kfunc from "../utill/util";

export default function OTP(props) {
  var location = useLocation();
  const [PhoneN, setPhoneN] = useState(`0885256214`);
  const [Otp, setOtp] = useState({ otp: "" });
  const [play, setplay] = useState(false);
  const [ErrorOtp, setErrorOtp] = useState(false);
  const [DisOtp, setDisOtp] = useState(false);
  const [TextErr, setTextErr] = useState(``);
  const [BT, setBT] = useState(true);
  const [seconds, setSeconds] = useState(30);
  const [TextBT, setTextBT] = useState(``);
  const [Frist, setFrist] = useState(false);
  const [refCode, setrefCode] = useState(``);

  useEffect(() => {
    CountDown(location.PhoneN);
    // console.log(location.PhoneN)
    if (location.PhoneN && Frist === false) {
      setPhoneN(location.PhoneN);
      setFrist(true);
      SendOTPTwilio();
      // console.log(`location.PhoneN ${PhoneN}`)
      // test();
    }
  });
  function test() {
    console.log(`Test`);
  }

  function CountDown() {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
      setTextBT(`ส่งได้อีกครั้งใน ${seconds} วินาที`);
    } else {
      setBT(false);
      setTextBT(`ส่งอีกครั้ง`);
    }
  }

  // useEffect(() => {
  //   if (Otp.otp.length >= 6) {
  //     console.log(Otp.otp);
  //     window.confirmationResult
  //       .confirm(Otp.otp)
  //       .then((result) => {
  //         // User signed in successfully.
  //         setDisOtp(true);
  //         setBT(true);
  //         const user = result.user;
  //         // console.log(JSON.stringify(user));
  //         // alert("User is verified");
  //         // ...
  //         const mikritik = async () => {
  //           try {
  //             var reg = {
  //               phone: PhoneN,
  //               name: `${location.fname} ${location.lname}`,
  //               path: "mikritik",
  //               port: "5036",
  //             };
  //             const REGIS = await Kfunc.fetchlocal(reg);
  //             if (REGIS.status == false) {
  //               alert(`ผิดพลาดกรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่`);
  //             } else {
  //               var jsondata3 = {
  //                 database: "CI_Docker_CountAPI",
  //                 table: "GuestWifi",
  //                 insert: {
  //                   fname: location.fname,
  //                   lname: location.lname,
  //                   phone: PhoneN,
  //                 },
  //                 path: "inserter",
  //                 port: "5003",
  //               };
  //               const MYSQL = await Kfunc.fetchlocal(jsondata3);
  //               console.log(MYSQL);
  //               alert(`ลงทะเบียนสำเร็จ`);
  //               window.location.replace("http://10.20.10.1");
  //             }
  //           } catch (error) {
  //             alert(`ขออภัย ไม่สามารถลงทะเบียนได้`);
  //             window.location.replace("http://10.20.10.1");
  //           }
  //         };
  //         mikritik();
  //       })
  //       .catch((error) => {
  //         setTextErr(`รหัส OTP ไม่ถูกต้อง`);
  //         setErrorOtp(true);
  //         setBT(false);
  //         // User couldn't sign in (bad verification code?)
  //         // ...
  //       });
  //   }
  // }, [Otp]);

  //verifiednew
  useEffect(() => {
    if (Otp.otp.length == 6) {
      console.log(Otp.otp);
      console.log(`Verify`);
      const SMS = async () => {
        var queryservice = {
          phone: `+66${location.PhoneN.substring(1, 10)}`,
          otp_code: Otp.otp,
          token: refCode,
          path: "ChecksmsmktOTP",
          port: "5015",
        };
        const Qser = await Kfunc.fetchlocalApp(queryservice);
        console.log(Qser);
        if (Qser.code == "000") {
          const mikritik = async () => {
            try {
              var reg = {
                phone: PhoneN,
                name: `${location.fname} ${location.lname}`,
                path: "mikritik",
                port: "5036",
              };
              const REGIS = await Kfunc.fetchlocal(reg);
              console.log(REGIS);
              if (REGIS.status == false) {
                alert(`ระบบผิดพลาด หรือเคยลงทะเบียนแล้วกรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่`);
              } else {
                var jsondata3 = {
                  database: "CI_Docker_CountAPI",
                  table: "GuestWifi",
                  insert: {
                    fname: location.fname,
                    lname: location.lname,
                    phone: PhoneN,
                  },
                  path: "inserter",
                  port: "5003",
                };
                const MYSQL = await Kfunc.fetchlocal(jsondata3);
                console.log(MYSQL);
                alert(`ลงทะเบียนสำเร็จ`);
                window.location.replace("http://10.20.10.1");
              }
            } catch (error) {
              alert(`ขออภัย ไม่สามารถลงทะเบียนได้`);
              window.location.replace("http://10.20.10.1");
            }
          };
          mikritik();
        } else {
          setTextErr(`รหัส OTP ไม่ถูกต้อง`);
          setErrorOtp(true);
          setBT(false);
        }
      };
      SMS();
    }
  }, [Otp]);

  // function SendOTP() {
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //     "recaptcha-container",
  //     {
  //       size: "invisible",
  //       callback: function (response) {
  //         console.log("Captcha Resolved");
  //         // this.onSignInSubmit();
  //       },
  //       defaultCountry: "TH",
  //     }
  //   );
  //   setDisOtp(true);
  //   var phoneotp = `+66${location.PhoneN.substring(1, 10)}`;
  //   let appVerifier = window.recaptchaVerifier;
  //   firebase
  //     .auth()
  //     .signInWithPhoneNumber(phoneotp, appVerifier)
  //     .then(function (confirmationResult) {
  //       console.log(confirmationResult);
  //       setDisOtp(false);
  //       // SMS sent. Prompt user to type the code from the message, then sign the
  //       // user in with confirmationResult.confirm(code).
  //       window.confirmationResult = confirmationResult;
  //       // console.log(confirmationResult);
  //       console.log("OTP is sent");
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //       setTextErr(`ไม่สามารถส่ง SMS ได้`);
  //     });
  // }

  function SendOTPTwilio() {
    console.log(`SMSTWI`);
    setDisOtp(true);
    const SMS = async () => {
      var queryservice = {
        phone: `+66${location.PhoneN.substring(1, 10)}`,
        path: "reqsmsmktOTP",
        port: "5015",
        project_key: "5f2437fbbf"
      };
      const Qser = await Kfunc.fetchlocalApp(queryservice);
      console.log(Qser);
      if (Qser.code == "000") {
        setrefCode(Qser.result.token);
        setDisOtp(false);
      } else {
        alert(`ขออภัย ไม่สามารถส่งsmsได้`);
      }
    };
    SMS();
  }

  return (
    <div className="container">
      <div id="recaptcha-container"></div>
      <div className="view">
        <div className="card">
          <form>
            <p>
              รหัสส่งSMSไปที่เบอร์ {PhoneN.substring(0, 3)}-
              {PhoneN.substring(3, 6)}-XXXX
            </p>

            <div className="margin-top--small">
              <OtpInput
                inputStyle="inputStyle"
                numInputs={6}
                isDisabled={DisOtp}
                hasErrored={ErrorOtp}
                errorStyle="error"
                value={Otp.otp}
                onChange={(e) => {
                  setOtp({ ...Otp, otp: e });
                  setplay(true);
                }}
                separator={<span>-</span>}
                isInputNum={true}
                isInputSecure={false}
                shouldAutoFocus
              />
            </div>
            <h4 style={{ color: "red" }}>{TextErr}</h4>
            <div className="btn-row">
              {/* <button
                  className="btn margin-top--large"
                  type="button"
                  disabled={isDisabled || otp.trim() === ''}
                  onClick={this.clearOtp}
                >
                  Clear
                </button> */}
              <button
                className="btn margin-top--large"
                disabled={BT}
                onClick={() => {
                  SendOTPTwilio();
                  setSeconds(15);
                  CountDown();
                  setBT(true);
                  console.log(`Click`);
                }}
              >
                {TextBT}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
