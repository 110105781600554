import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import date from "date-and-time";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import remy from "./ags.png";
import loadgif from "./3ef0e69f3c889c1307330c36a501eb12.gif";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Kfunc from "../utill/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Formik, Form } from "formik";
import * as yup from "yup";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useWait, Waiter } from "react-wait";
import "./styles.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import ImageIcon from "@material-ui/icons/Image";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Redirect, useNavigate } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import Popup from "react-popup";
import Image from "material-ui-image";
import { ReactSession } from "react-client-session";

export default function testpage() {
    return(
        <Container component="main" maxWidth="xs">
            <div>Hello</div>
        </Container>
    );
}