import axios from "axios";
// import CryptoJS from "CryptoJS"
import CryptoJS from "crypto-js";
import jwt from "jsonwebtoken";
import date from "date-and-time";
axios.defaults.timeout = 60000;
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
var fetch = _interopDefault(require("node-fetch"));
// var CryptoJS = _interopDefault(require("CryptoJS"));
class Kfunc {
  //Docker-CI
  fetchlocal = (jsondata) => {
    return new Promise((resolve, reject) => {
      var dateNow = new Date();
      var DateString = dateNow.toUTCString();
      var keyP = `A^Amps9@_Um_=^-9tfwJ&d&!pFqgppnK9=JWtFrJqxq=m=5H*3U@%&f%R@+Nsymz&@aC_8tbq6gYjM*R#6mqJ!7A^ZPYwAG3P!8C*dR2zuc33`;
      // console.log(date.format(dateNow, 'MM/DD'));
      // var encrept = CryptoJS.AES.encrypt(DateString, keyP).toString();
      // console.log(AES.SHA256(DateString + keyP).toString());
      var SECRET = CryptoJS.HmacSHA256(
        date.format(dateNow, "MM/DD"),
        keyP
      ).toString();
      // console.log(SECRET);
      var payload = {
        sub: "dockerapi-22022203889234",
        iat: new Date().getTime(),
      };

      // console.log(jwt.sign(payload, SECRET, { expiresIn: "1d" }));
      // console.log(JSON.stringify(jsondata));
      async function insertmong() {
        try {
          var option = {
            method: "post",
            body: JSON.stringify(jsondata),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: jwt.sign(payload, SECRET, { expiresIn: "1d" }),
              path: jsondata.path,
              port: jsondata.port,
            },
          };
          // console.log(option);
          let sheetupdate = await fetch(
            "https://dockerapi-ci.prachakij.com/jwtauth",
            option
          );
          let sheetdata = await sheetupdate.json();
          return resolve(sheetdata);
        } catch (error) {}
      }
      insertmong();
    });
  };
  //Docker-APP
  // fetchlocalApp = (jsondata) => {
  //   return new Promise((resolve, reject) => {
  //     async function insertmong() {
  //       try {
  //         var option = {
  //           method: "post",
  //           body: JSON.stringify(jsondata),
  //           headers: {
  //             Accept: "application/json",
  //             authorization:
  //               "Basic YWdzLWNpOmNkV21XLG81SGxOUGR9Z3pFWmxrYnpDSih6RHRQKQ==",
  //             "Content-Type": "application/json",
  //             path: jsondata.path,
  //             port: jsondata.port,
  //           },
  //         };
  //         // console.log(option);
  //         let sheetupdate = await fetch(
  //           "https://dockerapi.prachakij.com/",
  //           option
  //         );
  //         let sheetdata = await sheetupdate.json();
  //         return resolve(sheetdata); 
  //       } catch (error) {}
  //     }
  //     insertmong();
  //   });
  // };

  fetchlocalApp = (jsondata) => {
    return new Promise((resolve, reject) => {
      var dateNow = new Date();
      var DateString = dateNow.toUTCString();
      var keyP = `A^Amps9@_Um_=^-9tfwJ&d&!pFqgppnK9=JWtFrJqxq=m=5H*3U@%&f%R@+Nsymz&@aC_8tbq6gYjM*R#6mqJ!7A^ZPYwAG3P!8C*dR2zuc33`;
      // console.log(date.format(dateNow, 'MM/DD'));
      // var encrept = CryptoJS.AES.encrypt(DateString, keyP).toString();
      // console.log(AES.SHA256(DateString + keyP).toString());
      var SECRET = CryptoJS.HmacSHA256(
        date.format(dateNow, "MM/DD"),
        keyP
      ).toString();
      // console.log(SECRET);
      var payload = {
        sub: "dockerapi-22022203889234",
        iat: new Date().getTime(),
      };

      // console.log(jwt.sign(payload, SECRET, { expiresIn: "1d" }));
      // console.log(JSON.stringify(jsondata));
      async function insertmong() {
        try {
          var option = {
            method: "post",
            body: JSON.stringify(jsondata),
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              authorization: jwt.sign(payload, SECRET, { expiresIn: "1d" }),
              path: jsondata.path,
              port: jsondata.port,
            },
          };
          console.log(option);
          let sheetupdate = await fetch(
            "https://dockerapi-ci.prachakij.com/jwtauth",
            option
          );
          let sheetdata = await sheetupdate.json();
          return resolve(sheetdata);
        } catch (error) {}
      }
      insertmong();
    });
  };

  TOKEN = () => {
    return new Promise((resolve, reject) => {
      async function insertmong() {
        var dateNow = new Date();
        var DateString = dateNow.toUTCString();
        var keyP = `A^Amps9@_Um_=^-9tfwJ&d&!pFqgppnK9=JWtFrJqxq=m=5H*3U@%&f%R@+Nsymz&@aC_8tbq6gYjM*R#6mqJ!7A^ZPYwAG3P!8C*dR2zuc33`;
        // console.log(date.format(dateNow, 'MM/DD'));
        // var encrept = CryptoJS.AES.encrypt(DateString, keyP).toString();
        // console.log(AES.SHA256(DateString + keyP).toString());
        var SECRET = CryptoJS.HmacSHA256(
          date.format(dateNow, "MM/DD"),
          keyP
        ).toString();
        // console.log(SECRET);
        var payload = {
          sub: "dockerapi-22022203889234",
          iat: new Date().getTime(),
        };
        try {
          const token = await jwt.sign(payload, SECRET, { expiresIn: "1d" });
          console.log(token);
          return resolve(token);
        } catch (error) {}
      }
      insertmong();
    });
  };

  ///Linenotify
  Linenotify = (jsondata) => {
    return new Promise((resolve, reject) => {
      async function insertmong() {
        var dateNow = new Date();
        var DateString = dateNow.toUTCString();
        var keyP = `A^Amps9@_Um_=^-9tfwJ&d&!pFqgppnK9=JWtFrJqxq=m=5H*3U@%&f%R@+Nsymz&@aC_8tbq6gYjM*R#6mqJ!7A^ZPYwAG3P!8C*dR2zuc33`;
        // console.log(date.format(dateNow, 'MM/DD'));
        // var encrept = CryptoJS.AES.encrypt(DateString, keyP).toString();
        // console.log(AES.SHA256(DateString + keyP).toString());
        var SECRET = CryptoJS.HmacSHA256(
          date.format(dateNow, "MM/DD"),
          keyP
        ).toString();
        // console.log(SECRET);
        var payload = {
          sub: "dockerapi-22022203889234",
          iat: new Date().getTime(),
        };
        var option = {
          method: "post",
          body: JSON.stringify(jsondata),
          headers: {
            Accept: "application/json",
            authorization: jwt.sign(payload, SECRET, { expiresIn: "1d" }),
            "Content-Type": "application/json",
            path: "sendnotify",
            port: "5015",
          },
        };
        // console.log(option);
        let sheetupdate = await fetch(
          "https://dockerapi-ci.prachakij.com/jwtauth",
          option
        );
        let sheetdata = await sheetupdate.json();
        return resolve(sheetdata);
      }
      insertmong();
    });
  };
}
export default new Kfunc();
