import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import date from "date-and-time";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import remy from "./ags.png";
import loadgif from "./3ef0e69f3c889c1307330c36a501eb12.gif";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Kfunc from "../utill/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
// import NumberFormat from "react-number-format";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import { LineLogin } from "reactjs-line-login";
// import "reactjs-line-login/dist/index.css";
// import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import * as yup from "yup";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useWait, Waiter } from "react-wait";
import "./styles.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from '@material-ui/core/Avatar';
import ImageIcon from "@material-ui/icons/Image";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Redirect } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import Popup from "react-popup";
import Image from "material-ui-image";
import { ReactSession } from "react-client-session";

// const liff = window.liff;
let SignupSchema = yup.object().shape({
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  password: yup
    .string()
    .min(6, "Password is too short.")
    .max(20, "Password is too long.")
    .required("This field is required."),
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
export default function Signup() {
  // export const Signup = () => {
  const classes = useStyles();
  /////////////////////////////////BUTON////////////////////////////
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [password, setpassword] = useState("");
  const [paylode, setpaylode] = useState(false);
  const [phone, setphone] = useState(false);
  const [button, setbutton] = useState(false);
  const [Name, setName] = useState(false);
  const [FnameLname, setFnameLname] = useState(false);
  const [brance, setbrance] = useState("");
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [age, setAge] = React.useState("");
  const [Showdetail, setShowdetail] = useState(true);
  // const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  // var { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  const [values, setValues] = React.useState({
    textmask: "   -       ",
    numberformat: "1320",
  });

  const [Pic, setPic] = useState(remy);
  const [ques, setques] = useState({
    ques1: ``,
    ques2: ``,
    ques3: ``,
    Id_ques: ``,
  });

  // namecus, //ใช้ หาลูกค้าที่มีอยู่ในระบบ
  // Ck_NameF, // name
  // Ck_NameL,
  // picture,
  // Fistname, //ใช้
  // Lastname, //FORMATION
  // Card,
  // phone,
  // bracecus, //FORMATION
  // GroupName, //FORMATION
  // Ck_Brance, //FORMATION
  // idfb //FORMATION ของCK

  const [object, setobject] = useState({
    ckname: ``, //Ck_NameF
    ckLname: ``, //Ck_NameL
    groupName: ``, //GroupName
    userId: ``, //idfb
    Ck_Brance: ``, //Ck_Brance
    bu: ``, //bracecus
    typeGroup: ``,
    namecus: ``, //namecus
    Fistname: ``, //Fistname
    Lastname: ``, //Lastname
  });

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  ////////////////////////////////////select-data///////////////////////

  const [Showbrance, setShowbrance] = useState(true);
  const [CHchannel, setCHchannel] = useState("");
  const [date, setdate] = useState("");
  const [open, setOpen] = useState(false);
  const [Otp, setOtp] = useState({ otp: "" });
  const [Disphone, setDisphone] = useState(false);
  const [Disname, setDisname] = useState(true);

  const [botname, setbotname] = useState("");
  const [accept, setaccept] = useState(false);

  ///
  const [fbUser, setfbUser] = useState(false);
  const [TypeGroup, setTypeGroup] = useState(false);
  const [Brance, setBrance] = useState(false);
  const [Port, setPort] = useState(false);

  var url = _interopDefault(require("url"));
  var urlParts = url.parse(window.location.href, true);
  var query = urlParts.query;

  const select = (e) => {
    var jspare = JSON.parse(e.target.value);
    setobject({
      ...object,
      namecus: jspare.MsnName,
    });
  };
  const selectType = (e) => {
    var jspare = JSON.parse(e.target.value);
    // console.log(e.target.value)
    setobject({
      ...object,
      typeGroup: jspare.Grouptype,
    });
  };

  const selectBrance = (e) => {
    var jspare = JSON.parse(e.target.value);
    // console.log(e.target.value)
    setobject({
      ...object,
      bu: jspare.bracese,
    });
  };

  useEffect(() => {
    if (query.path !== undefined) {
      ReactSession.setStoreType("localStorage");
      var logintele = ReactSession.get("id_telegram");
      var dateLogintele = ReactSession.get("auth_date");
      if (logintele && dateLogintele > Math.floor(new Date() / 1000)) {
        (async () => {
          var queryservice = {
            database: "CI_Docker_MessengerInstance",
            table: "Ck_Data",
            query: { key: "telegram_Id", query: logintele },
            path: "finder",
            port: "5003",
          };
          const Qser = await Kfunc.fetchlocal(queryservice);
          // console.log("Qser", Qser);
          if (Qser.length > 0) {
            //แยกสาขา
            var Selectpack;
            if (query.path == "Facebook_Rafco") {
              Selectpack = {
                collectq: "MsnRafco_Sender",
                collectgrouptype: "GroupType_Rafco",
                collectBrance: "Brance",
              };
              setPort("5030");
              setPic(
                `https://linebotkeep-file.s3.ap-southeast-1.amazonaws.com/image/06042022-041445-882.jpeg`
              );
            } else if (query.path == "Facebook_AICP") {
              Selectpack = {
                collectq: "MsnAIC_Sender",
                collectgrouptype: "GroupType_AIC",
                collectBrance: "brance_AIC",
              };
              setPort("5032");
              setPic(
                `https://linebotkeep-file.s3.ap-southeast-1.amazonaws.com/image/06042022-041458-975.jpeg`
              );
            }
            // setCK
            const splitName = Qser[0].Ck_NameF.split(" ");
            setobject({
              ...object,
              ckname: splitName[0],
              ckLname: splitName[1],
              userId: Qser[0].idfb,
              Ck_Brance: Qser[0].Ck_Brance,
            });

            queryservice.database = `CI_Docker_MessengerInstance`;
            queryservice.table = Selectpack.collectq;
            queryservice.query = undefined;
            queryservice.path = `findlimit`;
            queryservice.limit = 50;
            const findSender = await Kfunc.fetchlocal(queryservice);
            // console.log("findSender", findSender);
            findSender.unshift({
              MsnName: `เลือก`,
            });
            setfbUser(findSender);
            queryservice.table = Selectpack.collectgrouptype;
            const findGtype = await Kfunc.fetchlocal(queryservice);
            findGtype.unshift({
              Grouptype: `เลือก`,
            });
            setTypeGroup(findGtype);
            queryservice.table = Selectpack.collectBrance;
            const findBrance = await Kfunc.fetchlocal(queryservice);
            // console.log(findBrance);
            findBrance.unshift({
              bracese: `เลือก`,
            });
            setBrance(findBrance);
            setbutton(true);
            // console.log(fbUser);
            // console.log(TypeGroup);
            // console.log(Brance);
          } else {
            alert("ไม่มี Id telegram นี้");
          }
        })();
      } else {
        if (window.location.hostname === `pghejob.prachakij.com`) {
          setbotname(`karan509_bot`);
          setSuccess(true);
        }
        if (window.location.hostname === `pghejob.duckdns.org`) {
          setbotname(`KaranTestDev_bot`);
          setSuccess(true);
        }
      }
    } else {
      setbutton(false);
      alert(`path ไม่ถูกต้อง`);
    }
  }, []);

  useEffect(() => {
    setobject({
      ...object,
      groupName: `${object.typeGroup}. ${object.Fistname} ${object.Lastname} ${object.bu}`,
    });
  }, [object.typeGroup, object.Fistname, object.Lastname, object.bu]);

  const handleTelegramResponse = (response) => {
    console.log(response);
    (async () => {
      if (response.id) {
        console.log(response);
        // alert(JSON.stringify(response));
        var queryservice = {
          database: "CI_Docker_telegram",
          table: "admin",
          query: { key: "telegram_id", query: response.id },
          path: "finder",
          port: "5003",
        };
        const Qser = await Kfunc.fetchlocal(queryservice);
        if (Qser.length > 0) {
          ReactSession.set("id_telegram", response.id);
          ReactSession.set("emp_id", Qser[0].emp_id);
          ReactSession.set("auth_date", response.auth_date + 604800);
          window.location.reload();
          console.log(Math.floor(new Date() / 1000));
        } else {
          alert(`ไม่อยู่ในระบบ admin`);
        }
      }
    })();
  };

  function Spinner() {
    return <img className="spinner" width="24" height="24" src={loadgif} />;
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function closepage() {
    Swal.fire({
      imageUrl:
        "https://mongodb.prachakij.com/web3/02022022-145611-317_complete.jpg",
      imageWidth: 700,
      imageHeight: 900,
      imageAlt: "A tall image",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ฉันเข้าใจและยอมรับเงื่อนใขทั้งหมด",
      cancelButtonText: "ไม่ยอมรับ",
    }).then((result) => {
      if (result.isConfirmed) {
        setaccept(true);
        Swal.fire({
          position: "top-end",
          icon: "success",
          // title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
    // Swal.fire({
    //   title: "saved successfully!",
    //   text: "กรุณาปิดหน้าเว็ปนี้",
    //   imageUrl:
    //     "https://cdn.dribbble.com/users/819881/screenshots/5076126/media/ef8524d6a04949c21106a9f41ebefb99.gif",
    //   imageWidth: 600,
    //   imageHeight: 400,
    //   imageAlt: "Custom image",
    // });
  }

  function UserCreateButton2() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    return (
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        // disabled={loading}
        onClick={() => {
          ReactSession.set("id_telegram", null);
          window.location.reload();
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ออกจากระบบ
        </Wait>
      </Button>
    );
  }

  function UserCreateButton() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    // console.log(`test`);
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={buttonClassname}
        // disabled={loading}

        // link: ``,
        // groopName: ``,
        // userId: ``,
        // groupId: ``,
        // bu: ``,
        onClick={async () => {
          //Event
          await startWaiting("creating user");
          // console.log(object);
          if (object.ckname === "") {
            //
            alert(`ข้อมูลไม่ครบ ckname`);
            endWaiting("creating user");
          } else if (object.groupName === "") {
            //
            alert(`ข้อมูลไม่ครบ groupName`);
            endWaiting("creating user");
          } else if (object.userId === "") {
            //
            alert(`ข้อมูลไม่ครบ userId`);
            endWaiting("creating user");
          } else if (object.Ck_Brance === "") {
            //
            alert(`ข้อมูลไม่ครบ  Ck_Brance`);
            endWaiting("creating user");
          } else if (object.bu === "") {
            //
            alert(`ข้อมูลไม่ครบ  bu`);
            endWaiting("creating user");
          } else if (object.typeGroup === "") {
            //
            alert(`ข้อมูลไม่ครบ typeGroup`);
            endWaiting("creating user");
          } else if (object.namecus === "") {
            //
            alert(`ข้อมูลไม่ครบ namecus`);
            endWaiting("creating user");
          } else if (object.Fistname === "") {
            //
            alert(`ข้อมูลไม่ครบ Fistname`);
            endWaiting("creating user");
          } else if (object.Lastname === "") {
            //
            alert(`ข้อมูลไม่ครบ Lastname`);
            endWaiting("creating user");
          } else {
            // console.log(object);
            (async () => {
              await startWaiting("creating user");
              //createGroup
              var jsondata3 = {
                namecus: object.namecus,
                Ck_NameF: object.ckname,
                Ck_NameL: object.ckLname,
                picture: ``,
                Fistname: object.Fistname,
                Lastname: object.Lastname,
                Card: ``,
                phone: ``,
                bracecus: object.bu,
                GroupName: object.groupName,
                Ck_Brance: object.Ck_Brance,
                idfb: object.userId,
                path: "creatgroupfb",
                port: Port,
              };
              // console.log(jsondata3);
              //
              const CreateGroup = await Kfunc.fetchlocal(jsondata3);
              // console.log("MYSQL", CreateGroup);
              // endWaiting("creating user");
              if (CreateGroup.status == `insertsuccess`) {
                // alert('AddSuccess')
                Swal.fire({
                  title: `<strong>บันทึกสำเร็จ</strong>`,
                  icon: "success",
                  html: "กดที่ปุ่ม  <b>Save!</b>, ",
                  showCloseButton: true,
                  focusConfirm: false,
                  confirmButtonText: "<b>Save!</b>",
                  allowOutsideClick: () => !Swal.isLoading(),
                  preConfirm: (login) => {
                    endWaiting("creating user");
                    window.close();
                  },
                });
              } else {
                // alert("AddFail");
                Swal.fire({
                  title: `<strong>ERROR</strong>`,
                  icon: "error",
                  html: "กดที่ปุ่ม  <b>Save!</b>, ",
                  showCloseButton: true,
                  focusConfirm: false,
                  confirmButtonText: "<b>Save!</b>",
                  allowOutsideClick: () => !Swal.isLoading(),
                  preConfirm: (login) => {
                    endWaiting("creating user");
                    window.location.reload();
                  },
                });
              }
            })();
          }
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ตกลง
        </Wait>
      </Button>
    );
  }

  return (
    // <Smsotp />
    <Container component="main" maxWidth="xs">
      {/* <Smsotp /> */}
      <CssBaseline />
      <Popup />
      <div className={classes.paper}>
        <Avatar alt="Remy Sharp" src={Pic} className={classes.large} />
        {/* <Typography component="h1" variant="h5">
          ระบบบันทึกงานบริการ
        </Typography> */}
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountBoxIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="ลงทะเบียนกลุ่มลูกค้า Mesesnger RPTN | AICP"
              secondary={date}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
        {/* <ValidationTextField
          className={classes.margin}
          required
          component="h1"
          // disabled
          id="filled-disabled"
          variant="h5"
          variant="outlined"
          defaultValue="ระบบบันทึกงานบริการ"
          id="validation-outlined-input"
        /> */}
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            // console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              {button === true ? (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl className={classes.margin} fullWidth>
                      <InputLabel htmlFor="demo-customized-select-native">
                        Facebook
                      </InputLabel>
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={ques.Id_ques}
                        onChange={select}
                        fullWidth
                        input={<BootstrapInput />}
                      >
                        {fbUser.map((v, index) => (
                          <option
                            key={index}
                            name={v.MsnName}
                            value={JSON.stringify(v)}
                          >
                            {v.MsnName}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl className={classes.margin} fullWidth>
                      <InputLabel htmlFor="demo-customized-select-native">
                        สาขา
                      </InputLabel>
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={ques.Id_ques}
                        onChange={selectBrance}
                        fullWidth
                        input={<BootstrapInput />}
                      >
                        {Brance.map((v, index) => (
                          <option
                            key={index}
                            name={v.bracese}
                            value={JSON.stringify(v)}
                          >
                            {v.bracese}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  {Showbrance === true ? (
                    <Grid item xs={12}>
                      <FormControl className={classes.margin} fullWidth>
                        <InputLabel htmlFor="demo-customized-select-native">
                          ประเภทกลุ่มลูกค้า
                        </InputLabel>
                        <NativeSelect
                          id="demo-customized-select-native"
                          // value={ques.Id_ques}
                          onChange={selectType}
                          fullWidth
                          input={<BootstrapInput />}
                        >
                          {TypeGroup.map((v, index) => (
                            <option
                              key={index}
                              name={v.Grouptype}
                              value={JSON.stringify(v)}
                            >
                              {v.Grouptype}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Grid>
                  ) : null}
                  <Grid item xs={12}>
                    <TextField
                      // error={phone === true}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        e.preventDefault();
                        // s(e.target.value);
                        setobject({ ...object, Fistname: e.target.value });
                      }}
                      // name="number"
                      label="ชื่อลูกค้า"
                      value={object.Fistname}
                      type="text"
                      id="outlined-error-helper-text"
                      disabled={Disphone}
                    />
                    {/* ) : null} */}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // error={phone === true}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        e.preventDefault();
                        // s(e.target.value);
                        setobject({ ...object, Lastname: e.target.value });
                      }}
                      // name="number"
                      label="นามสกุลลูกค้า"
                      value={object.Lastname}
                      type="text"
                      id="outlined-error-helper-text"
                      disabled={Disphone}
                    />
                    {/* ) : null} */}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // error={phone === true}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        // e.preventDefault();
                        // s(e.target.value);
                        setobject({ ...object, groupName: e.target.value });
                      }}
                      // name="number"
                      label="ชื่อกลุ่ม"
                      value={object.groupName}
                      type="text"
                      id="outlined-error-helper-text"
                      disabled={true}
                    />
                    {/* ) : null} */}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // error={phone === true}
                      variant="outlined"
                      fullWidth
                      // name="number"
                      label="CK"
                      value={object.ckname + " " + object.ckLname}
                      type="text"
                      id="outlined-error-helper-text"
                      disabled={true}
                    />
                    {/* ) : null} */}
                  </Grid>
                  {/* <Grid item xs={6}>
                  <TextField
                    error={FnameLname === true}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      e.preventDefault();
                      setFnameLname(e.target.value);
                    }}
                    name="number"
                    label="นามสกุล"
                    type="text"
                    id="outlined-error-helper-text"
                    // helperText="Incorrect entry."
                    autoComplete="lname"
                    helperText={FnameLname === true ? "กรุณาใส่ นามสกุล" : null}
                  />
                </Grid>
              </Grid> */}
                </Grid>
              ) : null}
              {/* <div className={classes.wrapper}>
                {button === true && accept === true ? (
                  <TelegramLoginButton
                    dataOnauth={handleTelegramResponse}
                    botName="karan509_bot"
                  />
                ) : null}
              </div> */}
              {/* {button === true ? (
                <div className={classes.wrapper}>
                  <Waiter>
                    <UserCreateButton />
                  </Waiter>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              ) : null} */}
              {button === true ? (
                <div className={classes.wrapper}>
                  <Waiter>
                    <UserCreateButton /> <UserCreateButton2 />
                  </Waiter>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              ) : null}

              <div className={classes.wrapper}>
                {button === false && success === true ? (
                  <TelegramLoginButton
                    dataOnauth={handleTelegramResponse}
                    botName={botname}
                  />
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );

  // }
}
