import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import date from "date-and-time";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import remy from "./ags.png";
import loadgif from "./3ef0e69f3c889c1307330c36a501eb12.gif";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Kfunc from "../utill/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
// import NumberFormat from "react-number-format";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import { LineLogin } from "reactjs-line-login";
// import "reactjs-line-login/dist/index.css";
// import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import * as yup from "yup";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useWait, Waiter } from "react-wait";
import "./styles.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from '@material-ui/core/Avatar';
import ImageIcon from "@material-ui/icons/Image";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Redirect } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import Popup from "react-popup";
import Image from "material-ui-image";

const liff = window.liff;
let SignupSchema = yup.object().shape({
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  password: yup
    .string()
    .min(6, "Password is too short.")
    .max(20, "Password is too long.")
    .required("This field is required."),
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
export default function Signup() {
  // export const Signup = () => {
  const classes = useStyles();
  /////////////////////////////////BUTON////////////////////////////
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [password, setpassword] = useState("");
  const [paylode, setpaylode] = useState(false);
  const [phone, setphone] = useState(false);
  const [button, setbutton] = useState(true);
  const [Name, setName] = useState(false);
  const [FnameLname, setFnameLname] = useState(false);
  const [brance, setbrance] = useState("");
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [age, setAge] = React.useState("");
  const [Showdetail, setShowdetail] = useState(true);
  // const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  // var { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  const [values, setValues] = React.useState({
    textmask: "   -       ",
    numberformat: "1320",
  });

  const [Pic, setPic] = useState(remy);
  const [ques, setques] = useState({
    ques1: ``,
    ques2: ``,
    ques3: ``,
    Id_ques: ``,
  });

  const [object, setobject] = useState({
    fname: ``,
    lname: ``,
    phone: ``,
    emp_email: ``,
    company_management: ``,
  });

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  ////////////////////////////////////select-data///////////////////////

  const [Showbrance, setShowbrance] = useState(true);
  const [CHchannel, setCHchannel] = useState("");
  const [date, setdate] = useState("");
  const [open, setOpen] = useState(false);
  const [Otp, setOtp] = useState({ otp: "" });
  const [Disphone, setDisphone] = useState(false);
  const [Disname, setDisname] = useState(true);
  const [label, setlabel] = useState({ fname: "ชื่อ", lname: "นามสกุล" });
  const [botname, setbotname] = useState("");
  const [accept, setaccept] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePH = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    if (window.location.hostname === `pghejob.prachakij.com`) {
      setbotname(`karan509_bot`);
      setbutton(false);
    }
    if (window.location.hostname === `pghejob.duckdns.org`) {
      setbotname(`KaranTestDev_bot`);
      setbutton(false);
    }
    // window.location.href = "https://google.com/contact";
    // closepage();
  }, []);

  function Spinner() {
    return <img className="spinner" width="24" height="24" src={loadgif} />;
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function closepage() {
    Swal.fire({
      imageUrl: "https://placeholder.pics/svg/300x1500",
      imageHeight: 1500,
      imageAlt: "A tall image",
    });
    // Swal.fire({
    //   title: "saved successfully!",
    //   text: "กรุณาปิดหน้าเว็ปนี้",
    //   imageUrl:
    //     "https://cdn.dribbble.com/users/819881/screenshots/5076126/media/ef8524d6a04949c21106a9f41ebefb99.gif",
    //   imageWidth: 600,
    //   imageHeight: 400,
    //   imageAlt: "Custom image",
    // });
  }

  const handleTelegramResponse = (response) => {
    var url = _interopDefault(require("url"));
    var urlParts = url.parse(window.location.href, true);
    var query = urlParts.query;
    // console.log(response);
    const regtelegram = async () => {
      if (response.id) {
        // console.log(response.id);
        var queryserviceCon = {
          database: "CI_Docker_telegram",
          table: "telegram_group_pgh",
          query: { key: "userID", query: response.id },
          path: "finder",
          port: "5003",
        };
        const Qser = await Kfunc.fetchlocal(queryserviceCon);
        console.log(Qser.length);
        if (Qser.length > 0) {
          // alert(
          //   `id_telegram ${response.first_name} ${response.last_name} เคยลงทะเบียนไปแล้วด้วยชื่อ ${Qser[0].telegram_name}`
          // );
          // window.close();
          var tokenjwt  = await Kfunc.TOKEN()
          if (query.file_name) {
            window.location.href = `https://mongodb.prachakij.com/web3/${query.file_name}?token=${tokenjwt}`;
            // console.log(Kfunc.TOKEN());
          }
        } else {
          alert(`ไม่ได้อยู่ในระบบกลุ่ม telegram`)
        }
      }
    };
    regtelegram();
  };

  return (
    // <Smsotp />
    <Container component="main" maxWidth="xs">
      {/* <Smsotp /> */}
      <CssBaseline />
      <Popup />
      <div className={classes.paper}>
        <Avatar alt="Remy Sharp" src={Pic} className={classes.large} />
        {/* <Typography component="h1" variant="h5">
          ระบบบันทึกงานบริการ
        </Typography> */}
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountBoxIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="ระบบป้องกันไฟล์ PKG-GROUP"
              // secondary={date}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              <div className={classes.wrapper}>
                {button === false ? (
                  <TelegramLoginButton
                    dataOnauth={handleTelegramResponse}
                    botName={botname}
                  />
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
}
