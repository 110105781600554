import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { store, useGlobalState } from "state-pool";
import "./styles.css";
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
var liff = window.liff;
var url = _interopDefault(require("url"));
var urlParts = url.parse(window.location.href, true);
var query = urlParts.query;

store.setState("user", { name: "Yezy", age: 25 });

export default function ProtectedComponent(props) {
  const [linelog, setlinelog] = useState(false);
  useEffect(() => {
    const Liffin = async () => {
      if (window.location.hostname === `pghejob.prachakij.com`) {
        // if (query.path) {
        //   setlinelog(true);
        // } else if (query.liffName != undefined) {
        //   await liff.init({ liffId: `1653921718-Xnv9Zqwe` }).catch((err) => {
        //     throw err;
        //   });
        //   if (liff.isLoggedIn()) {
        setlinelog(true);
        // const context = liff.getContext();
        // console.log(context);
        // alert(JSON.stringify(context));
        //   } else {
        //     var repath = `https://${window.location.hostname}/?liffName=${query.liffName}&GroupId=${query.GroupId}`;
        //     liff.login({
        //       redirectUri: repath,
        //     });
        //   }
        // } else {
        //   setlinelog(true);
        // }
      } else if (window.location.hostname === `lineadmin.prachakij.com`) {
        // ############################AAM#########################
        await liff.init({ liffId: `1653921718-pmQEdR8W` }).catch((err) => {
          throw err;
        });
        if (liff.isLoggedIn()) {
          setlinelog(true);
        } else {
          var repath = `https://${window.location.hostname}/?groupid=${query.groupid}`;
          if (query.path == `loginjson`) {
            repath = `https://${window.location.hostname}/?path=${query.path}`;
          }
          liff.login({
            redirectUri: repath,
          });
        }
      } else if (window.location.hostname === `pghejob.duckdns.org`) {
        // ############################test#########################
        console.log("test");
        console.log(query);
        console.log(window.location.href);
        //alert('test1')

        var s = window.location.href.split("?");
        console.log(s[1]);      

        if(query.path == "line_regisMR"){
          console.log("test1");
          // alert('test2')
          // alert(query.path)
          // alert(window.location.href)
          //initLine();
          

          // if (liff.isLoggedIn()) {
          //   alert('test0')
          //   setlinelog(true);
          // } else {
          //   var repath = `https://${window.location.hostname}/?userId=${query.userId}`;
          //   liff.login({
          //     redirectUri: repath,
          //   });
          // }
          // let split = window.location.href.split("?");
          // var qpath2 = `/${query.path}?${split[1]}`;
          // return <Redirect to={qpath2} />;
          
          

          // await liff.init({ liffId: `1653921718-ql0vRr2a` }).catch((err) => {
          //   throw err;
          // });
          //initLine();
          //window.location.href = `https://pghejob.duckdns.org/?path=line_regisMR`;

          // if (liff.isLoggedIn()) {
          //   setlinelog(true);
          // } else {
          //   var repath = `https://${window.location.hostname}/?userId=${query.userId}`;
          //   liff.login({
          //     redirectUri: repath,
          //   });
          // }
          // await liff.init({ liffId: `1653921718-pmQEdR8W` }).catch((err) => {
          //   throw err;
          // });
          // initLine();

        }
        // if (query.path) {
        //   // alert('test2')
        //   setlinelog(true);
        // } else if (query.liffName != undefined) {
        //   // alert('test')
        //   await liff.init({ liffId: `1653921718-ql0vRr2a` }).catch((err) => {
        //     throw err;
        //   });
        //   if (liff.isLoggedIn()) {
        setlinelog(true);
        // const context = liff.getContext();
        // console.log(context);
        // alert(JSON.stringify(context));
        //   } else {
        //     var repath = `https://${window.location.hostname}/?liffName=${query.liffName}&GroupId=${query.GroupId}`;
        //     liff.login({
        //       redirectUri: repath,
        //     });
        //   }
        // } else {
        //   // alert('test2')
        //   setlinelog(true);
        // }
      }
    };
    Liffin();
  }, []);

  const initLine = () => {
    liff.init({ liffId: '1653921718-ql0vRr2a' }, () => {
      if (liff.isLoggedIn()) {
        console.log("222222")
        alert('test3')
        runApp();
      } else {
        console.log("333333")
        liff.login();
      }
    }, err => console.error(err));
  }

  const runApp = () => {
    const idToken = liff.getIDToken();
    liff.getProfile().then(profile => {
      console.log(profile);
      alert(JSON.stringify(profile))
      // var repath = `https://${window.location.hostname}/?path=line_regisMR&userId=${profile.userId}&displayName=${profile.displayName}`;
      // let split = window.location.href.split("?");
      //   var qpath1 = `/?${split[1]}`;
      //   alert(qpath1)
      //   return <Redirect to={qpath1} />;
    }).catch(err => console.error(err));
  }

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">รอสักครู่...</div>;
    }

    return (
      <div className="timer">
        <div className="text">กำลังเปลี่ยนหน้า ...</div>
        <div className="value">{remainingTime}</div>
        <div className="text">วินาที</div>
      </div>
    );
  };
  if (linelog) {
    if (window.location.hostname === `pghejob.prachakij.com`) {
      if (query.path) {
        let split = window.location.href.split("?");
        var qpath = `/${query.path}?${split[1]}`;
        return <Redirect to={qpath} />;
      } else if (query.liffName === `aamLine`) {
        //AAM
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      } else if (query.liffName === `pmsLine`) {
        //pms
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      } 
      else if (query.liffName === `rafcoendjob`) {
        //rafco
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      }
      else if (query.liffName === `aicpendjob`) {
        //aicp
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      }else if (query.liffName === `pmsendjob`) {
        //pms new
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      }else if (query.liffName === `PGHchatinapp`) {
        //chat in app PGH
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      }
      else {
        let split = window.location.href.split("?");
        var qpath = `/pgh?${split[1]}`;
        return <Redirect to={qpath} />;
      }
    } else if (window.location.hostname === `lineadmin.prachakij.com`) {
      if (query.path) {
        let split = window.location.href.split("?");
        var qpath = `/${query.path}?${split[1]}`;
        return <Redirect to={qpath} />;
      } else {
        let split = window.location.href.split("?");
        var qpath = `/aam?${split[1]}`;
        return <Redirect to={qpath} />;
      }
    } else if (window.location.hostname === `pghejob.duckdns.org`) {
      if (query.path) {
        let split = window.location.href.split("?");
        var qpath = `/${query.path}?${split[1]}`;
        return <Redirect to={qpath} />;
      } else if (query.liffName === `aamLine`) {
        //AAM
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      } else if (query.liffName === `pmsLine`) {
        //pms
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      } 
      else if (query.liffName === `rafcoendjob`) {
        //rafco
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      }else if (query.liffName === `aicpendjob`) {
        //aicp
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      }else if (query.liffName === `pmsendjob`) {
        //pms new
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      }else if (query.liffName === `PGHchatinapp`) {
        //chat in app PGH
        let split = window.location.href.split("?");
        var qpath = `/${query.liffName}?${split[1]}`;
        return <Redirect to={qpath} />;
      }else if (query.liffName === `line_regisMR`) {
        //line_regisMR
        alert("llllll");
        let split = window.location.href.split("?");
        var qpath = `/${query.path}?${split[1]}`;
        return <Redirect to={qpath} />;
      }
      else {
        alert(query.code);
        let split = window.location.href.split("?");
        var qpath = `/pgh?${split[1]}`;
        return <Redirect to={qpath} />;
      }
    }
    // return <div> log </div>;
  } else {
    return (
      <div>
        <h1>
          LiffAppCenter PKG
          <br />
          ระบบกำลังเปลี่ยนหน้า
        </h1>
        <div className="timer-wrapper">
          <CountdownCircleTimer
            isPlaying
            duration={3}
            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
            onComplete={() => [true, 1000]}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
      </div>
    );
  }
}
