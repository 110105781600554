import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import date from "date-and-time";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import remy from "./aicpLOGO.jpg";
import loadgif from "./3ef0e69f3c889c1307330c36a501eb12.gif";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Kfunc from "../utill/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
// import NumberFormat from "react-number-format";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import { LineLogin } from "reactjs-line-login";
// import "reactjs-line-login/dist/index.css";
// import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import * as yup from "yup";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useWait, Waiter } from "react-wait";
import "./styles.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from '@material-ui/core/Avatar';
import ImageIcon from "@material-ui/icons/Image";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Redirect } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import SearchBar from "material-ui-search-bar";
import { store, useGlobalState } from "state-pool";

const liff = window.liff;
let SignupSchema = yup.object().shape({
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  password: yup
    .string()
    .min(6, "Password is too short.")
    .max(20, "Password is too long.")
    .required("This field is required."),
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
export default function Signup(props) {
  // export const Signup = () => {
  const classes = useStyles();
  /////////////////////////////////BUTON////////////////////////////
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [password, setpassword] = useState("");
  const [paylode, setpaylode] = useState(false);
  const [phone, setphone] = useState(false);
  const [button, setbutton] = useState(false);
  const [Name, setName] = useState(false);
  const [FnameLname, setFnameLname] = useState(false);
  const [brance, setbrance] = useState("");
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [age, setAge] = React.useState("");
  const [Showdetail, setShowdetail] = useState("");
  const [Branch, setBranch] = useState("");
  // const [SelectBranch, setSelectBranch] = useState("")
  // const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  // var { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  const [values, setValues] = React.useState({
    textmask: "   -       ",
    numberformat: "1320",
  });

  const [Pic, setPic] = useState(remy);
  const [ques, setques] = useState({
    ques1: ``,
    ques2: ``,
    ques3: ``,
    Id_ques: ``,
  });

  const [object, setobject] = useState({
    fname: ``,
    lname: ``,
    phone: ``,
    email_date: ``,
    emp_email: ``,
    search_input: ``,
    branch: ``,
  });

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  ////////////////////////////////////select-data///////////////////////

  const [Showbrance, setShowbrance] = useState(true);
  const [CHchannel, setCHchannel] = useState("");
  const [date, setdate] = useState("");
  const [open, setOpen] = useState(false);
  const [Otp, setOtp] = useState({ otp: "" });
  const [Disphone, setDisphone] = useState(false);
  const [Disname, setDisname] = useState(true);
  const [GroupName, setGroupName] = useState(true);
  const [label, setlabel] = useState({
    fname: "ชื่อกลุ่มลูกค้า",
    lname: "นามสกุล",
  });
  const [Loginstatetext, setLoginstatetext] = useState("for employees only!");

  const [user, setUser, updateUser] = useGlobalState("user");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePH = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  // var querystring = _interopDefault(require("querystring"));
  const select = (e) => {
    var jspare = JSON.parse(e.target.value);
    setobject({
      ...object,
      bu: jspare.Grouptype,
    });
    setDisname(false);
    // console.log(jspare);
    // alert(event.target.value.ques1)
  };

  const SelectBranch = (e) => {
    var jspare = JSON.parse(e.target.value);
    setobject({
      ...object,
      branch: jspare.bracese,
    });
    // setDisname(false);
    // console.log(jspare);
    // alert(event.target.value.ques1)
  };

  useEffect(() => {
    const initdata = async () => {
      var url = _interopDefault(require("url"));
      var urlParts = url.parse(window.location.href, true);
      var query = urlParts.query;
      // console.log(query.gid);
      // console.log(typeof query.gid);
      setIdToken(query.gid);
      const linkre = query.gname.replace(/๐/g, " ");
      // console.log(query.gname)
      // console.log(linkre)
      setGroupName(linkre);
    };
    initdata();
  }, []);

  function Spinner() {
    return <img className="spinner" width="24" height="24" src={loadgif} />;
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function UserCreateButton() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    return (
      <Button
        // type="submit"
        variant="contained"
        color="primary"
        className={buttonClassname}
        // disabled={loading}
        onClick={() => {
          var fun = async () => {
            await startWaiting("creating user");
            // console.log("Detail");
            // console.log(object);
            if (
              // token instra
              idToken == `` ||
              //ชื่อ ckmk
              FnameLname == "" ||
              // ชื่อกลุ่ม
              GroupName == `` ||
              // ประเภทกลุ่ม
              object.bu == ``
            ) {
              alert(`กรอกข้อมูลให้ครับ`);
              endWaiting("creating user");
            } else {
              // if (Showdetail !== "") {
              //   var queryserviceCon = {
              //     database: "BCT_AMS2_RAFCO",
              //     table: "BCT_Financing",
              //     query: { key: "contract_number", query: object.search_input },
              //     path: "finder",
              //     port: "5003",
              //   };
              //   const QserCon = await Kfunc.fetchlocal(queryserviceCon);
              //   if (QserCon.length > 0) {
              //     if (
              //       QserCon[0].telegram_token_1 === "" ||
              //       QserCon[0].telegram_token_1 === null
              //     ) {
              //       let newvalues = [
              //         { key: "telegram_token_1", newvalues: idToken },
              //         { key: "telegram_creat_time_1", newvalues: Showdetail },
              //         {
              //           key: "telegram_creat_user_1",
              //           newvalues: Name,
              //         },
              //       ];
              //       var jsondata3 = {
              //         database: "BCT_AMS2_RAFCO",
              //         table: "BCT_Financing",
              //         query: {
              //           key: "contract_number",
              //           query: object.search_input,
              //         },
              //         newvalues: newvalues,
              //         path: "updater",
              //         port: "5003",
              //       };

              //       const MYSQLCon = await Kfunc.fetchlocal(jsondata3);
              //       // console.log(MYSQLCon);

              //       if (MYSQLCon.status) {
              //         if (MYSQLCon.status == "UpdateSuccess") {
              //           alert(`บันทึกเลขที่สัญญา สำเร็จ`);
              //           // endWaiting("creating user");
              //           // window.location.reload();
              //         } else {
              //           alert(`เกิดข้อผิดพลาด บันทึกเลขที่สัญญา`);
              //           // endWaiting("creating user");
              //         }
              //       } else {
              //         alert(`เกิดข้อผิดพลาด บันทึกเลขที่สัญญา2`);
              //         // endWaiting("creating user");
              //       }
              //     } else {
              //       alert(`เลขที่สัญญานี้เคยลงทะเบียนแล้ว`);
              //     }
              //   } else {
              //     alert(`ไม่พบเลขที่สัญญา2`);
              //   }
              // }
              ////CIDOCKER
              var queryservice = {
                database: "CI_Docker_telegram",
                table: "telegram_group_aicp",
                query: { key: "token_instagram", query: idToken },
                path: "finder",
                port: "5003",
              };

              const Qser = await Kfunc.fetchlocal(queryservice);
              if (Qser.length > 0) {
                let newvalues = [
                  { key: "group_name", newvalues: GroupName },
                  { key: "ck_name", newvalues: FnameLname },
                  {
                    key: "group_type",
                    newvalues: object.bu,
                  },
                  {
                    key: "branch",
                    newvalues: object.branch,
                  },
                  // {
                  //   key: "contract_number",
                  //   newvalues: object.search_input,
                  // },
                ];
                var jsondata3 = {
                  database: "CI_Docker_telegram",
                  table: "telegram_group_aicp",
                  query: {
                    key: "token_instagram",
                    query: idToken,
                  },
                  newvalues: newvalues,
                  path: "updater",
                  port: "5003",
                  update: {
                    token_instagram: idToken,
                    group_name: GroupName,
                    ck_name: FnameLname,
                    group_type: object.bu,
                    branch: object.branch,
                    // contract_number: object.search_input
                  },
                };
                const MYSQLCon = await Kfunc.fetchlocal(jsondata3);
                if (MYSQLCon.status) {
                  if (MYSQLCon.status == "UpdateSuccess") {

                    jsondata3.path = "teleaicp";
                    jsondata3.port = "5031";
                    jsondata3.type = "update";
                    const Sheet = await Kfunc.fetchlocal(jsondata3);
                    
                    alert(`อัปเดทกลุ่ม สำเร็จ`);

                    endWaiting("creating user");
                    window.location.reload();
                  } else {
                    alert(`เกิดข้อผิดพลาด อัปเดทกลุ่ม`);
                    endWaiting("creating user");
                  }
                } else {
                  alert(`เกิดข้อผิดพลาด อัปเดทกลุ่ม2`);
                  endWaiting("creating user");
                }
                // alert(`กลุ่มนี้ลงทะเบียนแล้วชื่อ ${Qser[0].group_name}`);
                // endWaiting("creating user");
              } else {
                var jsondata3 = {
                  database: "CI_Docker_telegram",
                  table: "telegram_group_aicp",
                  insert: {
                    token_instagram: idToken,
                    group_name: GroupName,
                    ck_name: FnameLname,
                    group_type: object.bu,
                    branch: object.branch,
                    // contract_number: object.search_input
                  },
                  path: "inserter",
                  port: "5003",
                };

                const MYSQL = await Kfunc.fetchlocal(jsondata3);
                // console.log(MYSQL);

                if (MYSQL.sta) {
                  if (MYSQL.sta == "AddSuccess") {

                    jsondata3.path = "teleaicp";
                    jsondata3.port = "5031";
                    jsondata3.type = "insert";
                    const Sheet = await Kfunc.fetchlocal(jsondata3);
                    alert(`ลงทะเบียนสำเร็จ`);
                    endWaiting("creating user");
                    window.location.reload();
                  } else {
                    alert(`เกิดข้อผิดพลาด ลองใหม่`);
                    endWaiting("creating user");
                  }
                } else {
                  alert(`เกิดข้อผิดพลาด ลองใหม่`);
                  endWaiting("creating user");
                }
              }
            }
          };
          fun();
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ตกลง
        </Wait>
      </Button>
    );
  }

  const handleTelegramResponse = (response) => {
    // console.log(response);
    const regtelegram = async () => {
      if (response.id) {
        // console.log(response.id);
        var queryservice = {
          database: "CI_Docker_telegram",
          table: "admin",
          query: { key: "telegram_id", query: response.id },
          path: "finder",
          port: "5003",
        };
        const Qser = await Kfunc.fetchlocal(queryservice);
        if (Qser.length > 0) {
          // console.log(Qser[0].emp_email);
          setFnameLname(Qser[0].emp_name);
          setName(Qser[0].emp_email);
          // setobject({ ...object, emp_email: Qser[0].emp_email });
          // setobject({ ...object, emp_email: Qser[0].emp_email });
          var queryservice2 = {
            database: "CI_Docker_MessengerInstance",
            table: "GroupType_AIC",
            path: "finder",
            port: "5003",
          };

          const Qser2 = await Kfunc.fetchlocal(queryservice2);
          // console.log(Qser2);
          Qser2.unshift({
            Grouptype: "เลือกประเภทกลุ่ม",
          });
          // branch select
          var queryservice3 = {
            database: "CI_Docker_MessengerInstance",
            table: "brance_AIC",
            path: "finder",
            port: "5003",
          };

          const Qser3 = await Kfunc.fetchlocal(queryservice3);
          console.log(Qser3);
          Qser3.unshift({
            bracese: "เลือกสาขา",
          });
          //
          setpaylode(Qser2);
          setBranch(Qser3);
          setLoginstatetext(`ลงทะเบียนกลุ่มรับใช้ลูกค้า Telegram AICP`);
          setbutton(true);
          setobject({ ...object, phone: Qser[0].emp_id });
        } else {
          alert(`for employees only!`);
          // var jsondata3 = {
          //   database: "CI_Docker_telegram",
          //   table: "admin",
          //   insert: {
          //     telegram_id: response.id,
          //     emp_id: object.phone,
          //     telegram_name: `${response.first_name} ${response.last_name}`,
          //     emp_name: `${object.fname} ${object.lname}`,
          //   },
          //   path: "inserter",
          //   port: "5003",
          // };
          // const MYSQL = await Kfunc.fetchlocal(jsondata3);
          // console.log(MYSQL);
          // if (MYSQL.sta) {
          //   if (MYSQL.sta == "AddSuccess") {
          //     alert(`ลงทะเบียนสำเร็จ`);
          //     window.location.reload();
          //   } else {
          //     alert(`เกิดข้อผิดพลาด ลองใหม่`);
          //     window.location.reload();
          //   }
          // } else {
          //   alert(`เกิดข้อผิดพลาด ลองใหม่`);
          //   window.location.reload();
          // }
        }
      }
    };
    regtelegram();
  };

  // const searchfinanc = () => {
  //   // console.log(object.search_input);
  //   const start = async () => {
  //     var queryservice2 = {
  //       database: "BCT_AMS2_RAFCO",
  //       table: "BCT_Financing",
  //       query: { key: "contract_number", query: object.search_input },
  //       path: "finder",
  //       port: "5003",
  //     };

  //     const Qser2 = await Kfunc.fetchlocal(queryservice2);

  //     if (Qser2.length > 0) {
  //       var timeoption = {
  //         path: "dateNtime",
  //         port: "5040",
  //       };

  //       const TIME = await Kfunc.fetchlocal(timeoption);
  //       // console.log(TIME);
  //       if (TIME.error) {
  //         alert(`เกิดข้อผิดพลาด ลองใหม่ ${TIME.error}`);
  //       } else {
  //         // console.log(object.emp_email);
  //         // setobject({ ...object, email_date: `${emp_email}` });

  //         setShowdetail(`${TIME.stime}`);
  //       }
  //     } else {
  //       alert(`ไม่เจอเลขที่สัญญา`);
  //     }
  //   };
  //   start();
  // };

  if (open) {
    return (
      <Redirect
        to={{
          pathname: "/otp",
          PhoneN: object.phone,
          fname: object.fname,
          lname: object.lname,
        }}
      />
    );
  } else {
    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar alt="Remy Sharp" src={Pic} className={classes.large} />
          <List className={classes.root}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <AccountBoxIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={Loginstatetext}
                // primary="ลงทะเบียนกลุ่มรับใช้ลูกค้า TelegramRafco"
                secondary={date}
              />
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>

          {/* <ValidationTextField
          className={classes.margin}
          required
          component="h1"
          // disabled
          id="filled-disabled"
          variant="h5"
          variant="outlined"
          defaultValue="ระบบบันทึกงานบริการ"
          id="validation-outlined-input"
        /> */}
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ errors, handleChange, touched }) => (
              <Form className={classes.form}>
                {button === true ? (
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <TextField
                        // error={phone === true}
                        variant="outlined"
                        fullWidth
                        value={object.phone}
                        // onChange={(e) => {
                        //   e.preventDefault();
                        //   // s(e.target.value);
                        //   setobject({ ...object, phone: e.target.value });
                        // }}
                        // name="number"
                        label="รหัสพนักงาน CK&MK"
                        type="number"
                        id="outlined-error-helper-text"
                        disabled={true}
                      />
                      {/* ) : null} */}
                    </Grid>
                    {/* <Grid item xs={12}>
                      <SearchBar
                        placeholder={`บันทึกเลขที่สัญญา(กดenter)`}
                        value={object.search_input}
                        onChange={(e) => {
                          setobject({ ...object, search_input: e });
                        }}
                        onRequestSearch={() => {
                          console.log("enter");
                          searchfinanc();
                        }}
                      />
                    </Grid> */}
                    {Showdetail !== "" ? (
                      <Grid item xs={12}>
                        <TextField
                          // error={phone === true}
                          variant="outlined"
                          fullWidth
                          value={Showdetail}
                          // label="อีเมล์-วันที่ บันทึกตารางFinancing"
                          id="outlined-error-helper-text"
                          disabled={true}
                        />
                      </Grid>
                    ) : null}
                    <Grid item xs={12}>
                      {button === true ? (
                        <FormControl className={classes.margin} fullWidth>
                          <InputLabel htmlFor="demo-customized-select-native">
                            ประเภทกลุ่ม
                          </InputLabel>
                          <NativeSelect
                            id="demo-customized-select-native"
                            // value={ques.Id_ques}
                            onChange={select}
                            fullWidth
                            input={<BootstrapInput />}
                          >
                            {paylode.map((v) => (
                              <option
                                key={v.Grouptype}
                                name={v.Grouptype}
                                value={JSON.stringify(v)}
                              >
                                {v.Grouptype}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      {button === true ? (
                        <FormControl className={classes.margin} fullWidth>
                          <InputLabel htmlFor="demo-customized-select-native">
                            สาขา
                          </InputLabel>
                          <NativeSelect
                            id="demo-customized-select-native"
                            // value={ques.Id_ques}
                            onChange={SelectBranch}
                            fullWidth
                            input={<BootstrapInput />}
                          >
                            {Branch.map((v) => (
                              <option
                                key={v.bracese}
                                name={v.bracese}
                                value={JSON.stringify(v)}
                              >
                                {v.bracese}
                              </option>
                            ))}
                          </NativeSelect>
                        </FormControl>
                      ) : null}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        // error={phone === true}
                        disabled={true}
                        variant="outlined"
                        fullWidth
                        value={GroupName}
                        // onChange={(e) => {
                        //   e.preventDefault();
                        //   // s(e.target.value);
                        //   setobject({ ...object, fname: e.target.value });
                        // }}
                        // name="number"
                        // label={label.fname}
                        // type="number"
                        id="outlined-error-helper-text"
                      />
                      {/* ) : null} */}
                    </Grid>
                    {/* <Grid item xs={12}>
                      <TextField
                        // error={phone === true}
                        disabled={Disname}
                        variant="outlined"
                        fullWidth
                        value={object.lname}
                        onChange={(e) => {
                          e.preventDefault();
                          // s(e.target.value);
                          setobject({ ...object, lname: e.target.value });
                        }}
                        // name="number"
                        label={label.lname}
                        // type="number"
                        id="outlined-error-helper-text"
                      />
                    </Grid> */}
                  </Grid>
                ) : null}

                {/* <Grid item xs={6}>
                  <TextField
                    error={FnameLname === true}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      e.preventDefault();
                      setFnameLname(e.target.value);
                    }}
                    name="number"
                    label="นามสกุล"
                    type="text"
                    id="outlined-error-helper-text"
                    // helperText="Incorrect entry."
                    autoComplete="lname"
                    helperText={FnameLname === true ? "กรุณาใส่ นามสกุล" : null}
                  />
                </Grid>
              </Grid> */}
                <div className={classes.wrapper}>
                  {button === false ? (
                    <TelegramLoginButton
                      dataOnauth={handleTelegramResponse}
                      botName="karan509_bot"
                    />
                  ) : null}

                  {button === true ? (
                    <div className={classes.wrapper}>
                      <Waiter>
                        <UserCreateButton />
                      </Waiter>
                      {loading && (
                        <CircularProgress
                          size={24}
                          className={classes.buttonProgress}
                        />
                      )}
                    </div>
                  ) : null}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    );
  }

  // }
}
