import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import "./styles.css";
console.log(`EditRount1`);
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
var liff = window.liff;
var url = _interopDefault(require("url"));
var urlParts = url.parse(window.location.href, true);
var query = urlParts.query;
console.log(window.location.href);
console.log(query);

export default function Rounting_edit() {
  console.log(`11`);
  const [linelog, setlinelog] = useState(false);
  useEffect(() => {
    const Liffin = async () => {
      if (window.location.hostname === `pghejob.prachakij.com`) {
        // ############################PGH#########################
        if (query.path) {
          setlinelog(true);
        } else {
          await liff.init({ liffId: `1653921718-Xnv9Zqwe` }).catch((err) => {
            throw err;
          });
          if (liff.isLoggedIn()) {
            setlinelog(true);
          } else {
            liff.login({
              redirectUri: `https://${window.location.hostname}/edit?groupid=${query.groupid}`,
            });
          }
        }
      } else if (window.location.hostname === `lineadmin.prachakij.com`) {
        // ############################AAM#########################
        await liff.init({ liffId: `1653921718-pmQEdR8W` }).catch((err) => {
          throw err;
        });
        if (liff.isLoggedIn()) {
          setlinelog(true);
        } else {
          var repath = `https://${window.location.hostname}/?groupid=${query.groupid}`;
          if (query.path == `loginjson`) {
            repath = `https://${window.location.hostname}/?path=${query.path}`;
          }
          liff.login({
            redirectUri: repath,
          });
        }
      } else if (window.location.hostname === `pghejob.duckdns.org`) {
        // ############################test#########################
        console.log(`1`);
        if (query.path) {
          console.log(`2`);
          setlinelog(true);
        } else {
          console.log(`3`);
          await liff.init({ liffId: `1653921718-ql0vRr2a` }).catch((err) => {
            throw err;
          });
          //1653921718-ql0vRr2a
          if (liff.isLoggedIn()) {
            console.log(`4`);
            setlinelog(true);
          } else {
            console.log(`5`);
            var repath = `https://${window.location.hostname}/?groupid=${query.groupid}`;
            if (query.path == `loginjson`) {
              repath = `https://${window.location.hostname}/?path=${query.path}`;
            }
            liff.login({
              redirectUri: repath,
            });
          }
        }
      }
    };
    Liffin();
  }, []);

  const renderTime = ({ remainingTime }) => {
    if (remainingTime === 0) {
      return <div className="timer">รอสักครู่...</div>;
    }

    return (
      <div className="timer">
        <div className="text">กำลังเปลี่ยนหน้า ...</div>
        <div className="value">{remainingTime}</div>
        <div className="text">วินาที</div>
      </div>
    );
  };
  if (linelog) {
    if (window.location.hostname === `pghejob.prachakij.com`) {
      if (query.path) {
        console.log(`1`);
        let split = window.location.href.split("?");
        var qpath = `/${query.path}?${split[1]}`;
        return <Redirect to={qpath} />;
      } else {
        let split = window.location.href.split("?");
        var qpath = `/pgh?${split[1]}`;
        return <Redirect to={qpath} />;
      }
    } else if (window.location.hostname === `lineadmin.prachakij.com`) {
      if (query.path) {
        let split = window.location.href.split("?");
        var qpath = `/${query.path}?${split[1]}`;
        return <Redirect to={qpath} />;
      } else {
        let split = window.location.href.split("?");
        var qpath = `/aam?${split[1]}`;
        return <Redirect to={qpath} />;
      }
    } else if (window.location.hostname === `pghejob.duckdns.org`) {
      if (query.path) {
        console.log(`123`);
        let split = window.location.href.split("?");
        var qpath = `/${query.path}?${split[1]}`;
        return <Redirect to={qpath} />;
      } else {
        console.log(`124`);
        let split = window.location.href.split("?");
        var qpath = `/pgh?${split[1]}`;
        return <Redirect to={qpath} />;
      }
    }
    // return <div> log </div>;
  } else {
    return (
      <div>
        <h1>
          LiffAppCenter PKG
          <br />
          ระบบกำลังเปลี่ยนหน้า
        </h1>
        <div className="timer-wrapper">
          <CountdownCircleTimer
            isPlaying
            duration={3}
            colors={[["#004777", 0.33], ["#F7B801", 0.33], ["#A30000"]]}
            onComplete={() => [true, 1000]}
          >
            {renderTime}
          </CountdownCircleTimer>
        </div>
      </div>
    );
  }
}
