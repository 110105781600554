import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import date from "date-and-time";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import remy from "./ags.png";
import loadgif from "./3ef0e69f3c889c1307330c36a501eb12.gif";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Kfunc from "../utill/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
// import NumberFormat from "react-number-format";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import { LineLogin } from "reactjs-line-login";
// import "reactjs-line-login/dist/index.css";
// import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import * as yup from "yup";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useWait, Waiter } from "react-wait";
import "./styles.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from '@material-ui/core/Avatar';
import ImageIcon from "@material-ui/icons/Image";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Redirect } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import Popup from "react-popup";
import Image from "material-ui-image";
import { ReactSession } from "react-client-session";
import InputAdornment from '@mui/material/InputAdornment';

 const liff = window.liff;
let SignupSchema = yup.object().shape({
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  password: yup
    .string()
    .min(6, "Password is too short.")
    .max(20, "Password is too long.")
    .required("This field is required."),
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
export default function Signup() {
  // export const Signup = () => {
  const classes = useStyles();
  /////////////////////////////////BUTON////////////////////////////
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [password, setpassword] = useState("");
  const [paylode, setpaylode] = useState(false);
  const [phone, setphone] = useState(false);
  const [button, setbutton] = useState(true);
  const [Name, setName] = useState(false);
  const [FnameLname, setFnameLname] = useState(false);
  const [brance, setbrance] = useState("");
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [age, setAge] = React.useState("");
  const [Showdetail, setShowdetail] = useState(true);
  // const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  // var { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  const [values, setValues] = React.useState({
    textmask: "   -       ",
    numberformat: "1320",
  });

  const [Pic, setPic] = useState(remy);
  const [ques, setques] = useState({
    ques1: ``,
    ques2: ``,
    ques3: ``,
    Id_ques: ``,
  });

  const [object, setobject] = useState({
    name_telegram: ``,
    groupName: ``,
    userId: ``,
    groupId: ``,
    bu: ``,
    typeGroup: ``,
    mr_code: ``,
    displayName:``,
  });

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  ////////////////////////////////////select-data///////////////////////

  const [Showbrance, setShowbrance] = useState(true);
  const [CHchannel, setCHchannel] = useState("");
  const [date, setdate] = useState("");
  const [open, setOpen] = useState(false);
  const [Otp, setOtp] = useState({ otp: "" });
  const [Disphone, setDisphone] = useState(false);
  const [Disname, setDisname] = useState(true);

  const [botname, setbotname] = useState("");
  const [accept, setaccept] = useState(false);
  const [TypeGroup, setTypeGroup] = useState([`เลือก`, `O`, `P4`, `MR`]);

  const [pictureUrl, setPictureUrl] = useState(remy);
  //const [idToken, setIdToken] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [userId, setUserId] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePH = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  var url = _interopDefault(require("url"));
  var urlParts = url.parse(window.location.href, true);
  var query = urlParts.query;

  const select = (e) => {
    var jspare = JSON.parse(e.target.value);
    setobject({
      ...object,
      bu: jspare.bracese,
    });
    if (
      jspare.bracese === "PMS" ||
      jspare.bracese === "PMG" ||
      jspare.bracese === "PCC"
    ) {
      setShowbrance(false);
    }
  };
  const selectType = (e) => {
    // var jspare = JSON.parse(e.target.value);
    // console.log(e.target.value)
    setobject({
      ...object,
      typeGroup: e.target.value,
    });
  };

  const logout = () => {
    liff.logout();
    window.location.reload();
  }

  const initLine = () => {
    liff.init({ liffId: '1653921718-o1qwJvGr' }, () => {
      if (liff.isLoggedIn()) {
        runApp();
      } else {
        liff.login();
      }
    }, err => console.error(err));
  }

  const runApp = () => {
    const idToken = liff.getIDToken();
    setIdToken(idToken);
    liff.getProfile().then(profile => {
      console.log(profile);
      setDisplayName(profile.displayName);
      setPictureUrl(profile.pictureUrl);
      setStatusMessage(profile.statusMessage);
      setUserId(profile.userId);
      setobject({
            ...object,
            userId: profile.userId,
            displayName: profile.displayName
          });
    }).catch(err => console.error(err));
  }

  useEffect(() => {
    console.log(query);
    setbutton(true);

    initLine();

    // if (query.line_id !== undefined) {
    //   ReactSession.setStoreType("localStorage");
    //   var logintele = ReactSession.get("id_telegram");
    //   var dateLogintele = ReactSession.get("auth_date");
    //   var name_telegram = ReactSession.get("name_telegram");
    //   // console.log(dateLogintele);
    //   if (logintele && dateLogintele > Math.floor(new Date() / 1000)) {
    //     (async () => {
    //       var queryservice = {
    //         database: "CI_Docker_Social_Line",
    //         table: "out_brance",
    //         query: undefined,
    //         path: "finder",
    //         port: "5003",
    //       };
    //       const findBranc = await Kfunc.fetchlocal(queryservice);
    //       if (findBranc.length > 0) {
    //         findBranc.unshift({
    //           bracese: "เลือก",
    //         },{
    //           bracese: "MCM",
    //         });
    //         setobject({
    //           ...object,
    //           userId: query.line_id,
    //           name_telegram: name_telegram,
    //         });
    //         setpaylode(findBranc);
    //         setbutton(true);
    //         // closepage();
    //       } else {
    //         alert(`err bran`);
    //       }
    //       // setobject({
    //       //   ...object,
    //       //   userId: query.line_id,
    //       //   name_telegram: name_telegram
    //       // });
    //       // setbutton(true);
    //     })();
    //   } else {
    //     if (window.location.hostname === `pghejob.prachakij.com`) {
    //       setbotname(`karan509_bot`);
    //       setSuccess(true);
    //     }
    //     if (window.location.hostname === `pghejob.duckdns.org`) {
    //       setbotname(`KaranTestDev_bot`);
    //       setSuccess(true);
    //     }
    //   }
    // } else {
    //   //setbutton(false);
    //   alert(`ไม่มีไอดี`);
    // }
  }, []);

  const handleTelegramResponse = (response) => {
    console.log(response);
    (async () => {
      if (response.id) {
        console.log(response);
        // alert(JSON.stringify(response));
        var queryservice = {
          database: "CI_Docker_telegram",
          table: "admin",
          query: { key: "telegram_id", query: response.id },
          path: "finder",
          port: "5003",
        };
        const Qser = await Kfunc.fetchlocal(queryservice);
        if (Qser.length > 0) {
          ReactSession.set("id_telegram", response.id);
          ReactSession.set("emp_id", Qser[0].emp_id);
          ReactSession.set("auth_date", response.auth_date + 604800);
          ReactSession.set("name_telegram", response.username);
          window.location.reload();
        } else {
          alert(`ไม่อยู่ในระบบ admin`);
        }
      }
    })();
  };

  function Spinner() {
    return <img className="spinner" width="24" height="24" src={loadgif} />;
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function closepage() {
    Swal.fire({
      imageUrl:
        "https://mongodb.prachakij.com/web3/02022022-145611-317_complete.jpg",
      imageWidth: 700,
      imageHeight: 900,
      imageAlt: "A tall image",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ฉันเข้าใจและยอมรับเงื่อนใขทั้งหมด",
      cancelButtonText: "ไม่ยอมรับ",
    }).then((result) => {
      if (result.isConfirmed) {
        setaccept(true);
        Swal.fire({
          position: "top-end",
          icon: "success",
          // title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
    // Swal.fire({
    //   title: "saved successfully!",
    //   text: "กรุณาปิดหน้าเว็ปนี้",
    //   imageUrl:
    //     "https://cdn.dribbble.com/users/819881/screenshots/5076126/media/ef8524d6a04949c21106a9f41ebefb99.gif",
    //   imageWidth: 600,
    //   imageHeight: 400,
    //   imageAlt: "Custom image",
    // });
  }

  function UserCreateButton2() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    return (
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        // disabled={loading}
        onClick={() => {
          ReactSession.set("id_telegram", null);
          window.location.reload();
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ออกจากระบบ
        </Wait>
      </Button>
    );
  }

  function UserCreateButton() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={buttonClassname}
        // disabled={loading}

        // link: ``,
        // groopName: ``,
        // userId: ``,
        // groupId: ``,
        // bu: ``,
        onClick={async () => {
          //Event
          await startWaiting("creating user");
          // if (object.bu === "") {
          //   //
          //   alert("กรุณาเลือกสาขา");
          // } else if (object.userId === "") {
          //   //
          //   alert(`ไม่มีLIneไอดี`);
          // } else if (object.groupName === "") {
          //   //
          //   alert(`ไม่มีรหัสพนักงาน`);
          // } else {
            (async () => {
              //update
              await startWaiting("creating user");

              //insert
              console.log(object.displayName);
              var jsondata3 = {
                database: "CI_Docker_Social_Line",
                table: "Admin_insert_mr",
                insert: {
                  line_id: object.userId,
                  displayName: object.displayName,
                  mr_code: "MR-"+idToken,
                  
                },
                path: "adminsocial",
                port: "5003",
              };

              const MYSQL = await Kfunc.fetchlocal(jsondata3);
              console.log("MYSQL", MYSQL);
              endWaiting("creating user");
              if (MYSQL.sta == `AddSuccess`) {
                // alert('AddSuccess')
                Swal.fire({
                  title: `<strong>บันทึกสำเร็จ</strong>`,
                  icon: "success",
                  html: "กดที่ปุ่ม  <b>Save!</b>, ",
                  showCloseButton: true,
                  focusConfirm: false,
                  confirmButtonText: "<b>Save!</b>",
                  allowOutsideClick: () => !Swal.isLoading(),
                  preConfirm: (login) => {
                    window.close();
                  },
                });
              } else {
                // alert("AddFail");
                Swal.fire({
                  title: `<strong>ERROR</strong>`,
                  icon: "error",
                  html: "กดที่ปุ่ม  <b>Save!</b>, ",
                  showCloseButton: true,
                  focusConfirm: false,
                  confirmButtonText: "<b>Save!</b>",
                  allowOutsideClick: () => !Swal.isLoading(),
                  preConfirm: (login) => {
                    window.location.reload();
                  },
                });
              }
              endWaiting();
            })();
          //}
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ตกลง
        </Wait>
      </Button>
    );
  }

  return (
    // <Smsotp />
    <Container component="main" maxWidth="xs">
      {/* <Smsotp /> */}
      <CssBaseline />
      <Popup />
      <div className={classes.paper}>
        <Avatar alt="Remy Sharp" src={Pic} className={classes.large} />
        {/* <Typography component="h1" variant="h5">
          ระบบบันทึกงานบริการ
        </Typography> */}
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountBoxIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary="ลงทะเบียน MR" secondary={date} />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
        {/* <ValidationTextField
          className={classes.margin}
          required
          component="h1"
          // disabled
          id="filled-disabled"
          variant="h5"
          variant="outlined"
          defaultValue="ระบบบันทึกงานบริการ"
          id="validation-outlined-input"
        /> */}
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            // console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
          <Grid item xs={12}>
                  <TextField
                    error={idToken === true}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      e.preventDefault();
                      setIdToken(e.target.value);
                    }}
                    placeholder="000000"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">MR - </InputAdornment>,
                    }}
                    name="text"
                    label="รหัส MR"
                    type="text"
                    id="outlined-error-helper-text"
                    // helperText="Incorrect entry."
                    autoComplete="token"
                    helperText={idToken === true ? "กรุณาใส่ รหัส MR" : null}
                  />
               </Grid>
               {button === true ? (
                <div className={classes.wrapper}>
                  <ThemeProvider theme={theme}>
                    {/* <Button
                      type="submit"
                      size="large"
                      variant="contained"
                      color="primary"
                      // className={classes.margin}
                      // disabled={loading}
                      // onClick={initLine}
                    >
                      ตกลง
                    </Button> */}
                  </ThemeProvider>
                  <Waiter>
                    <UserCreateButton />
                  </Waiter>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.UserCreateButton}
                    />
                  )}
                </div>
              ) : null}

               </Form>
          )}

          
               
        </Formik>
      </div>
    </Container>
  );

  // }
}
