import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import date from "date-and-time";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import remy from "./pkglogo.png";
import loadgif from "./3ef0e69f3c889c1307330c36a501eb12.gif";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Kfunc from "../utill/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
// import NumberFormat from "react-number-format";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import { LineLogin } from "reactjs-line-login";
// import "reactjs-line-login/dist/index.css";
// import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import * as yup from "yup";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useWait, Waiter } from "react-wait";
import "./styles.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from '@material-ui/core/Avatar';
import ImageIcon from "@material-ui/icons/Image";
import WifiIcon from "@material-ui/icons/Wifi";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Redirect } from "react-router-dom";

const liff = window.liff;
let SignupSchema = yup.object().shape({
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  password: yup
    .string()
    .min(6, "Password is too short.")
    .max(20, "Password is too long.")
    .required("This field is required."),
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
export default function Signup() {
  // export const Signup = () => {
  const classes = useStyles();
  /////////////////////////////////BUTON////////////////////////////
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [password, setpassword] = useState("");
  const [paylode, setpaylode] = useState(false);
  const [phone, setphone] = useState(false);
  const [button, setbutton] = useState(true);
  const [Name, setName] = useState(false);
  const [FnameLname, setFnameLname] = useState(false);
  const [brance, setbrance] = useState("");
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [age, setAge] = React.useState("");
  const [Showdetail, setShowdetail] = useState(true);
  // const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  // var { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  const [values, setValues] = React.useState({
    textmask: "   -       ",
    numberformat: "1320",
  });

  const [Pic, setPic] = useState(remy);
  const [ques, setques] = useState({
    ques1: ``,
    ques2: ``,
    ques3: ``,
    Id_ques: ``,
  });

  const [object, setobject] = useState({
    fname: ``,
    lname: ``,
    phone: ``,
  });

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  ////////////////////////////////////select-data///////////////////////

  const [Showbrance, setShowbrance] = useState(true);
  const [CHchannel, setCHchannel] = useState("");
  const [date, setdate] = useState("");
  const [open, setOpen] = useState(false);
  const [Otp, setOtp] = useState({ otp: "" });
  const [Disphone, setDisphone] = useState(false);
  const [Disname, setDisname] = useState(true);
  const [label, setlabel] = useState({ fname: "ชื่อ", lname: "นามสกุล" });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePH = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  var querystring = _interopDefault(require("querystring"));
  const select = (e) => {
    var jspare = JSON.parse(e.target.value);
    setobject({
      ...object,
      Id_ques: jspare.Id_ques,
      bu: jspare.bu,
    });
    setques({
      ...ques,
      ques1: jspare.ques1,
      ques2: jspare.ques2,
      ques3: jspare.ques3,
    });
    console.log(jspare.ques1);
    // alert(event.target.value.ques1)
  };

  useEffect(() => {
    if (object.phone.length === 10) {
      var start = async () => {
        console.log(object.phone);
        setDisphone(true);
        var ph = object.phone;
        var x;
        if (ph !== ``) {
          if (ph.indexOf("-") > -1) {
            console.log("เบอร์ถูกต้อง");
          } else {
            console.log("เบอร์แปลง");
            x = ph
              .replace(/\D+/g, "")
              .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2$3");
            console.log(x);
            // object.phone = x;
          }
        }

        var queryservice = {
          database: "BCT_customer_datacenter",
          table: "customercenter",
          query: { key: "mobile", query: x },
          path: "finder",
          port: "5003",
        };
        const Qser = await Kfunc.fetchlocal(queryservice);
        if (Qser.length > 0) {
          if (Qser[0].fullname) {
            var nameSp = Qser[0].fullname.split(" ");
            console.log(nameSp);
            setobject({
              ...object,
              fname: nameSp[0],
              lname: nameSp[1],
            });
            setlabel({ ...label, fname: ``, lname: `` });
          } else {
            setDisphone(false);
            setDisname(false);
          }
        } else {
          setDisphone(false);
          setDisname(false);
        }
      };
      start();
    }
  }, [object.phone]);

  function Spinner() {
    return <img className="spinner" width="24" height="24" src={loadgif} />;
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const mikritik = async () => {
    try {
      var reg = {
        phone: object.phone,
        name: `${object.fname} ${object.lname}`,
        path: "mikritik",
        port: "5036",
      };
      const REGIS = await Kfunc.fetchlocal(reg);
      if (REGIS.status == false) {
        alert(`ผิดพลาดกรุณาลองใหม่ หรือติดต่อเจ้าหน้าที่`);
      } else {
        var jsondata3 = {
          database: "CI_Docker_CountAPI",
          table: "GuestWifi",
          insert: {
            fname: object.fname,
            lname: object.lname,
            phone: object.phone,
          },
          path: "inserter",
          port: "5003",
        };
        const MYSQL = await Kfunc.fetchlocal(jsondata3);
        console.log(MYSQL);
        alert(`ลงทะเบียนสำเร็จ`);
        window.location.replace("http://10.20.10.1");
      }
    } catch (error) {
      alert(`ขออภัย ไม่สามารถลงทะเบียนได้`);
      window.location.replace("http://10.20.10.1");
    }
  };

  function UserCreateButton() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    return (
      <Button
        type="submit"
        variant="contained"
        color="primary"
        className={buttonClassname}
        // disabled={loading}
        onClick={() => {
          var fun = async () => {
            await startWaiting("creating user");
            console.log("Detail");
            console.log(object);
            if (
              object.fname == "" ||
              // object.channel == `` ||
              object.lname == `` ||
              // object.product == `` ||
              // object.custype == `` ||
              object.phone == ``
            ) {
              alert(`กรอกข้อมูลให้ครับ`);
              endWaiting("creating user");
            } else if (object.phone.length !== 10) {
              alert(`ตรวจสอบเบอร์โทร`);
              endWaiting("creating user");
            } else if (object.phone.substring(0, 1) !== `0`) {
              alert(`เบอร์ต้องขึ้นต้นด้วย 0`);
              endWaiting("creating user");
            } else {
              var queryservice = {
                database: "CI_Docker_CountAPI",
                table: "GuestWifi",
                query: { key: "phone", query: object.phone },
                path: "finder",
                port: "5003",
              };
              const Qser = await Kfunc.fetchlocal(queryservice);
              if (Qser.length > 0) {
                // console.log(Qser)
                if (Qser[0].tester == `true`) {
                  setOpen(true);
                  endWaiting("creating user");
                } else {
                  alert(`เบอร์นี้มีในระบบแล้ว กรุณาใช้เบอร์อื่น`);
                  endWaiting("creating user");
                }
              } else {
                if (label.fname === ``) {
                  mikritik();
                } else {
                  console.log(`sendOTP`);
                  setOpen(true);
                }
              }
            }
          };
          fun();
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ตกลง
        </Wait>
      </Button>
    );
  }

  if (open) {
    return (
      <Redirect
        to={{
          pathname: "/otp",
          PhoneN: object.phone,
          fname: object.fname,
          lname: object.lname,
        }}
      />
    );
  } else {
    return (
      // <Smsotp />
      <Container component="main" maxWidth="xs">
        {/* <Smsotp /> */}
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar alt="Remy Sharp" src={Pic} className={classes.large} />
          {/* <Typography component="h1" variant="h5">
          ระบบบันทึกงานบริการ
        </Typography> */}
          <List className={classes.root}>
            <ListItem>
              <ListItemAvatar>
                <Avatar>
                  <WifiIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="ลงทะเบียนใช้ฟรี Wifi" secondary={date} />
            </ListItem>
            <Divider variant="inset" component="li" />
          </List>
          {/* <ValidationTextField
          className={classes.margin}
          required
          component="h1"
          // disabled
          id="filled-disabled"
          variant="h5"
          variant="outlined"
          defaultValue="ระบบบันทึกงานบริการ"
          id="validation-outlined-input"
        /> */}
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              password: "",
            }}
            validationSchema={SignupSchema}
            onSubmit={(values) => {
              console.log(values);
            }}
          >
            {({ errors, handleChange, touched }) => (
              <Form className={classes.form}>
                <Grid container spacing={1}>
                <Grid item xs={12}>
                  <TextField
                    // error={phone === true}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      e.preventDefault();
                      // s(e.target.value);
                      setobject({ ...object, phone: e.target.value });
                    }}
                    // name="number"
                    label="เบอร์โทร"
                    type="number"
                    id="outlined-error-helper-text"
                    disabled={Disphone}
                  />
                  {/* ) : null} */}
                </Grid>
                <Divider />
                <Divider />
                <Divider />

                <Grid item xs={12}>
                  <TextField
                    // error={phone === true}
                    disabled={Disname}
                    variant="outlined"
                    fullWidth
                    value={object.fname}
                    onChange={(e) => {
                      e.preventDefault();
                      // s(e.target.value);
                      setobject({ ...object, fname: e.target.value });
                    }}
                    // name="number"
                    label={label.fname}
                    // type="number"
                    id="outlined-error-helper-text"
                  />
                  {/* ) : null} */}
                </Grid>
                <Divider />
                <Divider />
                <Divider />
                <Divider />
                <Divider />
                <Divider />
                <Divider />
                <Grid item xs={12}>
                  <TextField
                    // error={phone === true}
                    disabled={Disname}
                    variant="outlined"
                    fullWidth
                    value={object.lname}
                    onChange={(e) => {
                      e.preventDefault();
                      // s(e.target.value);
                      setobject({ ...object, lname: e.target.value });
                    }}
                    // name="number"
                    label={label.lname}
                    // type="number"
                    id="outlined-error-helper-text"
                  />
                  {/* ) : null} */}
                </Grid>

                {/* <Grid item xs={6}>
                  <TextField
                    error={FnameLname === true}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      e.preventDefault();
                      setFnameLname(e.target.value);
                    }}
                    name="number"
                    label="นามสกุล"
                    type="text"
                    id="outlined-error-helper-text"
                    // helperText="Incorrect entry."
                    autoComplete="lname"
                    helperText={FnameLname === true ? "กรุณาใส่ นามสกุล" : null}
                  />
                </Grid>
              </Grid> */}
              </Grid>
                <div className={classes.wrapper}>
                  <Waiter>
                    <UserCreateButton />
                  </Waiter>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>

                {/* {button === true ? (
                <div className={classes.wrapper}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={buttonClassname}
                    disabled={loading}
                    onClick={lineliff}
                  >
                    เข้าสู่ระบบAD
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              ) : null} */}
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    );
  }

  // }
}
