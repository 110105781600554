import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import MUIDataTable from "mui-datatables";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from '@material-ui/core/Avatar';
import ImageIcon from "@material-ui/icons/Image";
import WorkIcon from "@material-ui/icons/Work";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import remy from "./1612860349382.jpg";
import Avatar from "@material-ui/core/Avatar";
import Kfunc from "../utill/util";
// import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
// const theme = createTheme();

function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}

export default function Signup() {
  const [responsive, setResponsive] = useState("vertical");
  const [tableBodyHeight, setTableBodyHeight] = useState("800px");
  const [tableBodyMaxHeight, setTableBodyMaxHeight] = useState("");
  const [state, setstate] = useState(false);
  const [data, setdata] = useState();
  const [columns, setcolumns] = useState();
  const [titlef, settitlef] = useState("");

  // const columns = ["Name", "Title", "Location", "ทดสอบ"];
  useEffect(() => {
    const fetch = async () => {
      var url = _interopDefault(require("url"));
      var urlParts = url.parse(window.location.href, true);
      var query = urlParts.query;
      // setbrance(query.groupid);
      var jsondata1 = {
        path: "teblesearch",
        port: "5057",
        id_table: query.id_table,
      };
      const fetch = await Kfunc.fetchlocal(jsondata1);
      settitlef(fetch.title);
      setcolumns(fetch.collum);
      setdata(fetch.row);
    };
    fetch();
  }, []);

  const options = {
    filter: true,
    filterType: "dropdown",
    responsive,
    tableBodyHeight,
    tableBodyMaxHeight,
    fixedSelectColumn: false,
    onRowClick: (dataIndex, rowIndex) => {
      // console.log(JSON.stringify(rowIndex));
      console.log(data[rowIndex.dataIndex]);
      // console.log(data);
      //  var dadd =  dta.splice(rowIndex.dataIndex, 1);
      //   console.log(dta);
      //   setdata(dadd);
    },
    // customToolbarSelect: (dataIndex, rowIndex) => {
    //   console.log(JSON.stringify(dataIndex));
    //   console.log(JSON.stringify(rowIndex));
    //   // console.log(data);
    //   //  var dadd =  dta.splice(rowIndex.dataIndex, 1);
    //   //   console.log(dta);
    //   //   setdata(dadd);
    // },
  };

  // const data2 = [
  //   ["Gabby George", "Business Analyst", "Minneapolis"],
  //   [
  //     "Aiden Lloyd",
  //     "Business Consultant for an International Company and CEO of Tony's Burger Palace",
  //     "Dallas",
  //   ],
  //   ["Jaden Collins", "Attorney", "Santa Ana"],
  //   ["Franky Rees", "Business Analyst", "St. Petersburg"],
  //   ["Aaren Rose", null, "Toledo"],
  //   ["Johnny Jones", "Business Analyst", "St. Petersburg"],
  //   ["Jimmy Johns", "Business Analyst", "Baltimore"],
  //   ["Jack Jackson", "Business Analyst", "El Paso"],
  //   ["Joe Jones", "Computer Programmer", "El Paso"],
  //   ["Jacky Jackson", "Business Consultant", "Baltimore"],
  //   ["Jo Jo", "Software Developer", "Washington DC"],
  //   ["Donna Marie", "Business Manager", "Annapolis"],
  // ];

  return (
    <List>
      <ListItem>
        <ListItemAvatar>
          <Avatar>
            <WorkIcon />
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={titlef} />
      </ListItem>
      <Divider variant="inset" component="li" />
      <MUIDataTable
        // title={titlef}
        data={data}
        columns={columns}
        options={options}
      />
    </List>
  );
}

// ReactDOM.render(<App />, document.getElementById("root"));
