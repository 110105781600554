import firebase from "firebase";
// import * as firebase from 'firebase/app'
import "firebase/auth";
// import { getAuth, RecaptchaVerifier } from "firebase/auth";
// var firebaseConfig = {
//     apiKey: "",
//     authDomain: "",
//     projectId: "",
//     storageBucket: "",
//     messagingSenderId: "",
//     appId: ""
//   };
var firebaseConfig = {
  apiKey: "AIzaSyCoRAkzOl2MCOJU0pWg8Zd-wo-DYB_HzbY",
  authDomain: "pghejob.firebaseapp.com",
  projectId: "pghejob",
  storageBucket: "pghejob.appspot.com",
  messagingSenderId: "908664397310",
  appId: "1:908664397310:web:16186be96d0044ff6ff4af",
  measurementId: "G-57MMKT3ET2",
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
export const { getAuth, RecaptchaVerifier } = firebase.auth() 
