import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import date from "date-and-time";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import {
  makeStyles,
  ThemeProvider,
  createMuiTheme,
  withStyles,
} from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Avatar from "@material-ui/core/Avatar";
import remy from "./ags.png";
import loadgif from "./3ef0e69f3c889c1307330c36a501eb12.gif";
import clsx from "clsx";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@material-ui/core/colors";
import Kfunc from "../utill/util";
import PropTypes from "prop-types";
import MaskedInput from "react-text-mask";
// import NumberFormat from "react-number-format";
// import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
// import { LineLogin } from "reactjs-line-login";
// import "reactjs-line-login/dist/index.css";
// import { v4 as uuidv4 } from "uuid";
import { Formik, Form } from "formik";
import * as yup from "yup";
import NativeSelect from "@material-ui/core/NativeSelect";
import InputBase from "@material-ui/core/InputBase";
import { useWait, Waiter } from "react-wait";
import "./styles.css";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
// import Avatar from '@material-ui/core/Avatar';
import ImageIcon from "@material-ui/icons/Image";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BeachAccessIcon from "@material-ui/icons/BeachAccess";
import Divider from "@material-ui/core/Divider";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import OtpInput from "react-otp-input";
import { Redirect, useNavigate } from "react-router-dom";
import TelegramLoginButton from "react-telegram-login";
import Popup from "react-popup";
import Image from "material-ui-image";
import { ReactSession } from "react-client-session";

// const liff = window.liff;
let SignupSchema = yup.object().shape({
  firstName: yup.string().required("This field is required."),
  lastName: yup.string().required("This field is required."),
  email: yup.string().email().required("This field is required."),
  password: yup
    .string()
    .min(6, "Password is too short.")
    .max(20, "Password is too long.")
    .required("This field is required."),
});

const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const useStyles = makeStyles((theme) => ({
  "@global": {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    margin: theme.spacing(3, 0, 2),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    color: green[500],
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    "&:hover": {
      backgroundColor: green[700],
    },
    margin: {
      margin: theme.spacing(1),
    },
  },
}));

const ValidationTextField = withStyles({
  root: {
    "& input:valid + fieldset": {
      borderColor: "green",
      borderWidth: 2,
    },
    "& input:invalid + fieldset": {
      borderColor: "red",
      borderWidth: 2,
    },
    "& input:valid:focus + fieldset": {
      borderLeftWidth: 6,
      padding: "4px !important", // override inline-style
    },
  },
})(TextField);

const theme = createMuiTheme({
  palette: {
    primary: green,
  },
});
function _interopDefault(ex) {
  return ex && typeof ex === "object" && "default" in ex ? ex["default"] : ex;
}
function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[/[0]/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};
export default function Signup() {
  // export const Signup = () => {
  const classes = useStyles();
  /////////////////////////////////BUTON////////////////////////////
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [password, setpassword] = useState("");
  const [paylode, setpaylode] = useState(false);
  const [phone, setphone] = useState(false);
  const [button, setbutton] = useState(false);
  const [Name, setName] = useState(false);
  const [FnameLname, setFnameLname] = useState(false);
  const [brance, setbrance] = useState("");
  const [payload, setPayload] = useState(null);
  const [idToken, setIdToken] = useState(null);
  const [age, setAge] = React.useState("");
  const [Showdetail, setShowdetail] = useState(true);
  const [Showline, setShowline] = useState(true);
  const [Disline, setDisline] = useState(true);

  // const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  // var { startWaiting, endWaiting, isWaiting, Wait } = useWait();
  const [values, setValues] = React.useState({
    textmask: "   -       ",
    numberformat: "1320",
  });

  const [Pic, setPic] = useState(remy);
  const [ques, setques] = useState({
    ques1: ``,
    ques2: ``,
    ques3: ``,
    Id_ques: ``,
  });

  const [object, setobject] = useState({
    link: ``,
    groupName: ``,
    userId: ``,
    groupId: ``,
    bu: ``,
    typeGroup: ``,
    lineAccont: ``,
  });

  const timer = React.useRef();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success,
  });
  ////////////////////////////////////select-data///////////////////////

  const [Showbrance, setShowbrance] = useState(true);
  const [CHchannel, setCHchannel] = useState("");
  const [date, setdate] = useState("");
  const [open, setOpen] = useState(false);
  const [Otp, setOtp] = useState({ otp: "" });
  const [Disphone, setDisphone] = useState(false);
  const [Disname, setDisname] = useState(true);

  const [botname, setbotname] = useState("");
  const [accept, setaccept] = useState(false);
  const [TypeGroup, setTypeGroup] = useState([`เลือก`, `O`, `P4`, `MR`]);
  const [account , setaccount] = useState([`เลือก`,`line1`,`line2`])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePH = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  var url = _interopDefault(require("url"));
  var urlParts = url.parse(window.location.href, true);
  var query = urlParts.query;

  const select = (e) => {
    var jspare = JSON.parse(e.target.value);
    setobject({
      ...object,
      bu: jspare.bracese,
    });
    if (
      jspare.bracese === "PMS" ||
      jspare.bracese === "PMG" ||
      jspare.bracese === "PCC"
    ) {
      setShowbrance(false);
      setShowline(false);
    }
  };
  const selectType = (e) => {
    // var jspare = JSON.parse(e.target.value);
    // console.log(e.target.value)
    setobject({
      ...object,
      typeGroup: e.target.value,
    });
  };

  const selectAccount = (e) => {
    // var jspare = JSON.parse(e.target.value);
    // console.log(e.target.value)
    setobject({
      ...object,
      lineAccont: e.target.value,
    });
  };

  useEffect(() => {
    if (query.GroupId !== undefined) {
      ReactSession.setStoreType("localStorage");
      var logintele = ReactSession.get("id_telegram");
      var dateLogintele = ReactSession.get("auth_date");
      if (logintele && dateLogintele > Math.floor(new Date() / 1000)) {
        (async () => {
          var queryservice = {
            database: "CI_Docker_telegram",
            table: "admin",
            query: { key: "telegram_id", query: logintele },
            path: "finder",
            port: "5003",
          };
          const Qser = await Kfunc.fetchlocal(queryservice);
          if (Qser.length > 0) {
            queryservice.database = `CI_Docker_Social_Line`;
            queryservice.table = `Admin_LIne`;
            queryservice.query = { key: "group_id", query: query.GroupId };
            const findGroup = await Kfunc.fetchlocal(queryservice);
            // console.log(findGroup);
            if (findGroup.length > 0) {
              // alert("มีกลุ่มนี้แล้ว");
              setaccept(true);
              if (
                findGroup[0].bracese === "PMS" ||
                findGroup[0].bracese === "PMG" ||
                findGroup[0].bracese === "PCC"
              ) {
                setShowbrance(false);
                setShowline(false);
                setobject({
                  ...object,
                  groupName: findGroup[0].group_name,
                  userId: logintele,
                  groupId: query.GroupId,
                  typeGroup: findGroup[0].grouptype,
                });

                // for (let index = 0; index < findunit.length; index++) {
                //   if (findunit[index].displayName) {

                //   }
                // }
              } else {
                setobject({
                  ...object,
                  groupName: findGroup[0].group_name,
                  userId: logintele,
                  groupId: query.GroupId,
                  bu: findGroup[0].brance,
                  typeGroup: findGroup[0].grouptype,
                });
              }

              if (findGroup[0].LinkG !== undefined) {
                setobject({
                  ...object,
                  link: findGroup[0].LinkG,
                  groupName: findGroup[0].group_name,
                  userId: logintele,
                  groupId: query.GroupId,
                  bu: findGroup[0].brance,
                  typeGroup: findGroup[0].grouptype,
                  lineAccont: findGroup[0].LINE,

                });
              }
            } else {
              setobject({
                ...object,
                userId: logintele,
                groupId: query.GroupId,
              });
            }
            // setbutton(true)
            queryservice.database = `CI_Docker_Social_Line`;
            queryservice.table = `out_brance`;
            queryservice.query = undefined;
            const findBranc = await Kfunc.fetchlocal(queryservice);
            if (findBranc.length > 0) {
              if (findGroup.length > 0) {
                findBranc.unshift({
                  bracese: findGroup[0].brance,
                });
                setTypeGroup([findGroup[0].grouptype, `O`, `P4`, `MR`]);
              } else {
                findBranc.unshift({
                  bracese: "เลือก",
                });
              }
              setpaylode(findBranc);
              setbutton(true);
              // closepage();
            } else {
              alert(`err bran`);
            }

            queryservice.database = `CI_Docker_Social_Line`;
            queryservice.table = `unitlinepms`;
            queryservice.query = undefined;
            const findunit = await Kfunc.fetchlocal(queryservice);
            const accountDisplay = findunit.map((item) => {
              return item.displayName;
            });
            accountDisplay.unshift("เลือก");
            // console.log(accountDisplay);
            setaccount(accountDisplay);
          } else {
            alert(`ไม่ได้ลงทะเบียนadmin`);
          }
        })();
      } else {
        if (window.location.hostname === `pghejob.prachakij.com`) {
          setbotname(`karan509_bot`);
          setSuccess(true);
        }
        if (window.location.hostname === `pghejob.duckdns.org`) {
          setbotname(`KaranTestDev_bot`);
          setSuccess(true);
        }
      }
    } else {
      setbutton(false);
      alert(`ไม่มีไอดีกลุ่ม`);
    }
  }, []);

  const handleTelegramResponse = (response) => {
    console.log(response);
    (async () => {
      if (response.id) {
        console.log(response);
        // alert(JSON.stringify(response));
        var queryservice = {
          database: "CI_Docker_telegram",
          table: "admin",
          query: { key: "telegram_id", query: response.id },
          path: "finder",
          port: "5003",
        };
        const Qser = await Kfunc.fetchlocal(queryservice);
        if (Qser.length > 0) {
          ReactSession.set("id_telegram", response.id);
          ReactSession.set("emp_id", Qser[0].emp_id);
          ReactSession.set("auth_date", response.auth_date + 604800);
          window.location.reload();
          console.log(Math.floor(new Date() / 1000));
        } else {
          alert(`ไม่อยู่ในระบบ admin`);
        }
      }
    })();
  };

  function Spinner() {
    return <img className="spinner" width="24" height="24" src={loadgif} />;
  }
  function timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  function closepage() {
    // Swal.fire({
    //   title: "saved successfully!",
    //   text: "กรุณาปิดหน้าเว็ปนี้",
    //   imageUrl:
    //     "https://cdn.dribbble.com/users/819881/screenshots/5076126/media/ef8524d6a04949c21106a9f41ebefb99.gif",
    //   imageWidth: 600,
    //   imageHeight: 400,
    //   imageAlt: "Custom image",
    // });

    // const navigate = useNavigate();
    // navigate("/closep", { replace: true })
    return <Redirect to={`/closep`} />;
  }

  function UserCreateButton2() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    const [isVisible1, setIsVisible1] = useState(true);
    return (
      <Button
        type="submit"
        variant="contained"
        color="secondary"
        data-inline="true"
        // disabled={loading}
        onClick={() => {
          ReactSession.set("id_telegram", null);
          window.location.reload();
        }}
        disabled={isWaiting("creating user")}
      >
        <Wait on="creating user" fallback={<Spinner />}>
          ออกจากระบบ
        </Wait>
      </Button>
    );
  }

  function UserCreateButton() {
    const { startWaiting, endWaiting, isWaiting, Wait } = useWait();
    const [disable, setDisable] = React.useState(false);
    const [isVisible, setIsVisible] = useState(true);

    return (
      <div style={{ display: isVisible ? "block" : "none" }}>
        <Button
          style={{ width: "50%" }}
          type="submit"
          variant="contained"
          color="primary"
          className={buttonClassname}
          // disabled={loading}

          // link: ``,
          // groopName: ``,
          // userId: ``,
          // groupId: ``,
          // bu: ``,
          onClick={async () => {
            //Event

            console.log(disable);
            await startWaiting("creating user");
            if (object.bu === "") {
              //
              alert("กรุณาเลือกสาขา");
            } else if (object.groupId === "") {
              //
              alert(`ไม่มีไอดีกลุ่ม`);
            }
            // else if (object.link) {
            //   //
            // }
            else if (object.userId === "") {
              //
              alert(`ไม่มีไอดีผู้ใช้`);
            } else if (object.groupName === "") {
              //
              alert(`ไม่มีชื่อกลุ่ม`);
            } else if (
              object.bu === "PMS" ||
              object.bu === "PMG" ||
              object.bu === "PCC"
            ) {
              //
              (async () => {
                if (accept == true) {
                  //update
                  
                  await startWaiting("creating user");
                  let newvalues3 = [
                    { key: "group_name", newvalues: object.groupName },
                    { key: "LinkG", newvalues: object.link },
                    { key: "save_at", newvalues: object.userId },
                    { key: "brance", newvalues: object.bu },
                    { key: "grouptype", newvalues: object.typeGroup },
                    { key: "LINE", newvalues: object.lineAccont },
                  ];
                  var jsondata3 = {
                    database: "CI_Docker_Social_Line",
                    table: "Admin_LIne",
                    query: { key: "group_id", query: object.groupId },
                    newvalues: newvalues3,
                    path: "updatesocialpgh",
                    port: "5003",
                  };
                  const UpMYSQL = await Kfunc.fetchlocal(jsondata3);
                  // console.log("UpMYSQL", UpMYSQL);
                  endWaiting("creating user");
                  if (UpMYSQL.status === "UpdateSuccess") {
                    //update
                    Swal.fire({
                      title: `<strong>อัปเดท สำเร็จ</strong>`,
                      icon: "success",
                      html: "กดที่ปุ่ม  <b>Update!</b>, ",
                      showCloseButton: true,
                      focusConfirm: false,
                      confirmButtonText: "<b>ตกลง!</b>",
                      allowOutsideClick: () => !Swal.isLoading(),
                      preConfirm: (login) => {
                        closepage();
                        window.close();
                      },
                    });
                  } else {
                    //!update
                    Swal.fire({
                      title: `<strong>อัปเดท ไม่สำเร็จ</strong>`,
                      icon: "error",
                      html: "กดที่ปุ่ม  <b>Update!</b>, ",
                      showCloseButton: true,
                      focusConfirm: false,
                      confirmButtonText: "<b>ตกลง!</b>",
                      allowOutsideClick: () => !Swal.isLoading(),
                      preConfirm: (login) => {
                        window.location.reload();
                      },
                    });
                  }
                } else {
                  //insert
                  var jsondata3 = {
                    database: "CI_Docker_Social_Line",
                    table: "Admin_LIne",
                    insert: {
                      group_id: object.groupId,
                      group_name: object.groupName,
                      LinkG: object.link,
                      save_at: object.userId,
                      brance: object.bu,
                      grouptype: object.typeGroup,
                      LINE: object.lineAccont,
                      employee_id: "NULL",
                      datestamp: "",
                    },
                    path: "insertsocialpgh",
                    port: "5003",
                  };
                  const MYSQL = await Kfunc.fetchlocal(jsondata3);
                  // console.log("MYSQL", MYSQL);
                  endWaiting("creating user");
                  if (MYSQL.sta == `AddSuccess`) {
                    // alert('AddSuccess')
                    Swal.fire({
                      title: `<strong>บันทึกสำเร็จ</strong>`,
                      icon: "success",
                      html: "กดที่ปุ่ม  <b>Save!</b>, ",
                      showCloseButton: true,
                      focusConfirm: false,
                      confirmButtonText: "<b>Save!</b>",
                      allowOutsideClick: () => !Swal.isLoading(),
                      preConfirm: (login) => {
                        closepage();
                        window.close();
                      },
                    });
                  } else {
                    // alert("AddFail");
                    Swal.fire({
                      title: `<strong>ERROR</strong>`,
                      icon: "error",
                      html: "กดที่ปุ่ม  <b>Save!</b>, ",
                      showCloseButton: true,
                      focusConfirm: false,
                      confirmButtonText: "<b>Save!</b>",
                      allowOutsideClick: () => !Swal.isLoading(),
                      preConfirm: (login) => {
                        window.location.reload();
                      },
                    });
                  }
                  endWaiting();
                }
              })();
            } else {
              //
              if (object.typeGroup === "") {
                alert(`ไม่มีประเภทกลุ่ม`);
              } else {
                (async () => {
                  if (accept == true) {
                    //update
                    await startWaiting("creating user");

                    let newvalues3 = [
                      { key: "group_name", newvalues: object.groupName },
                      { key: "LinkG", newvalues: object.link },
                      { key: "save_at", newvalues: object.userId },
                      { key: "brance", newvalues: object.bu },
                      { key: "grouptype", newvalues: object.typeGroup },
                      { key: "employee_id", newvalues: "NULL" },
                      { key: "datestamp", newvalues: "" },
                    ];
                    var jsondata3 = {
                      database: "CI_Docker_Social_Line",
                      table: "Admin_LIne",
                      query: { key: "group_id", query: object.groupId },
                      newvalues: newvalues3,
                      path: "updatesocialaamG",
                      port: "5003",
                    };
                    const UpMYSQL = await Kfunc.fetchlocal(jsondata3);
                    // console.log("UpMYSQL", UpMYSQL);
                    endWaiting("creating user");
                    if (UpMYSQL.status === "UpdateSuccess") {
                      //update
                      Swal.fire({
                        title: `<strong>อัปเดท สำเร็จ</strong>`,
                        icon: "success",
                        html: "กดที่ปุ่ม  <b>Update!</b>, ",
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: "<b>ตกลง!</b>",
                        allowOutsideClick: () => !Swal.isLoading(),
                        preConfirm: (login) => {
                          closepage();
                          window.close();
                        },
                      });
                    } else {
                      //!update
                      Swal.fire({
                        title: `<strong>อัปเดท ไม่สำเร็จ</strong>`,
                        icon: "error",
                        html: "กดที่ปุ่ม  <b>Update!</b>, ",
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: "<b>ตกลง!</b>",
                        allowOutsideClick: () => !Swal.isLoading(),
                        preConfirm: (login) => {
                          window.location.reload();
                        },
                      });
                    }
                  } else {
                    //insert
                    var jsondata3 = {
                      database: "CI_Docker_Social_Line",
                      table: "Admin_LIne",
                      insert: {
                        group_id: object.groupId,
                        group_name: object.groupName,
                        LinkG: object.link,
                        save_at: object.userId,
                        brance: object.bu,
                        grouptype: object.typeGroup,
                      },
                      path: "insertsocialaam",
                      port: "5003",
                    };
                    const MYSQL = await Kfunc.fetchlocal(jsondata3);
                    // console.log("MYSQL", MYSQL);
                    //endWaiting("creating user");
                    if (MYSQL.sta == `AddSuccess`) {
                      // alert('AddSuccess')
                      Swal.fire({
                        title: `<strong>บันทึกสำเร็จ</strong>`,
                        icon: "success",
                        html: "กดที่ปุ่ม  <b>Save!</b>, ",
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: "<b>Save!</b>",
                        allowOutsideClick: () => !Swal.isLoading(),
                        preConfirm: (login) => {
                          closepage();
                          window.close();
                        },
                      });
                    } else {
                      // alert("AddFail");
                      Swal.fire({
                        title: `<strong>ERROR</strong>`,
                        icon: "error",
                        html: "กดที่ปุ่ม  <b>Save!</b>, ",
                        showCloseButton: true,
                        focusConfirm: false,
                        confirmButtonText: "<b>Save!</b>",
                        allowOutsideClick: () => !Swal.isLoading(),
                        preConfirm: (login) => {
                          window.location.reload();
                        },
                      });
                    }
                    endWaiting();
                    setDisable(true);
                    setIsVisible(false);
                  }
                })();
              }
            }
          }}
          disabled={isWaiting("creating user")}
        >
          <Wait on="creating user" fallback={<Spinner />}>
            ตกลง
          </Wait>
        </Button>
      </div>
    );
  }

  return (
    // <Smsotp />
    <Container component="main" maxWidth="xs">
      {/* <Smsotp /> */}
      <CssBaseline />
      <Popup />
      <div className={classes.paper}>
        <Avatar alt="Remy Sharp" src={Pic} className={classes.large} />
        {/* <Typography component="h1" variant="h5">
          ระบบบันทึกงานบริการ
        </Typography> */}
        <List className={classes.root}>
          <ListItem>
            <ListItemAvatar>
              <Avatar>
                <AccountBoxIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="ลงทะเบียนกลุ่มลูกค้า AAM"
              secondary={date}
            />
          </ListItem>
          <Divider variant="inset" component="li" />
        </List>
        {/* <ValidationTextField
          className={classes.margin}
          required
          component="h1"
          // disabled
          id="filled-disabled"
          variant="h5"
          variant="outlined"
          defaultValue="ระบบบันทึกงานบริการ"
          id="validation-outlined-input"
        /> */}
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={(values) => {
            // console.log(values);
          }}
        >
          {({ errors, handleChange, touched }) => (
            <Form className={classes.form}>
              {button === true ? (
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FormControl className={classes.margin} fullWidth>
                      <InputLabel htmlFor="demo-customized-select-native">
                        สังกัด
                      </InputLabel>
                      <NativeSelect
                        id="demo-customized-select-native"
                        // value={ques.Id_ques}
                        onChange={select}
                        fullWidth
                        input={<BootstrapInput />}
                      >
                        {paylode.map((v) => (
                          <option
                            key={v.bracese}
                            name={v.bracese}
                            value={JSON.stringify(v)}
                          >
                            {v.bracese}
                          </option>
                        ))}
                      </NativeSelect>
                    </FormControl>
                  </Grid>
                  {Showbrance === true ? (
                    <Grid item xs={12}>
                      <FormControl className={classes.margin} fullWidth>
                        <InputLabel htmlFor="demo-customized-select-native">
                          ประเภทกลุ่มลูกค้า
                        </InputLabel>
                        <NativeSelect
                          id="demo-customized-select-native"
                          // value={ques.Id_ques}
                          onChange={selectType}
                          fullWidth
                          input={<BootstrapInput />}
                        >
                          {TypeGroup.map((v) => (
                            <option key={v} name={v} value={v}>
                              {v}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Grid>
                  ) : null}
                  {Showbrance === false ? (
                    <Grid item xs={12}>
                      <FormControl className={classes.margin} fullWidth>
                        <InputLabel htmlFor="demo-customized-select-native">
                          บัญชี LINE
                        </InputLabel>
                        <NativeSelect
                          id="demo-customized-select-native"
                          // value={ques.Id_ques}
                          onChange={selectAccount}
                          fullWidth
                          input={<BootstrapInput />}
                        >
                          {account.map((v) => (
                            <option key={v} name={v} value={v}>
                              {v}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </Grid>
                  ) : null}

                  <Grid item xs={12}>
                    <TextField
                      // error={phone === true}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        // e.preventDefault();
                        // s(e.target.value);
                        setobject({ ...object, groupName: e.target.value });
                      }}
                      // name="number"
                      label="ชื่อกลุ่ม"
                      value={object.groupName}
                      type="text"
                      id="outlined-error-helper-text"
                      disabled={Disphone}
                    />
                    {/* ) : null} */}
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      // error={phone === true}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        e.preventDefault();
                        // s(e.target.value);
                        setobject({ ...object, link: e.target.value });
                      }}
                      // name="number"
                      label="Link กลุ่ม"
                      value={object.link}
                      type="text"
                      id="outlined-error-helper-text"
                      disabled={Disphone}
                    />
                    {/* ) : null} */}
                  </Grid>
                  {Showline === false ? (
                  <Grid item xs={12}>
                    <TextField
                      // error={phone === true}
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        e.preventDefault();
                        // s(e.target.value);
                        setobject({ ...object, lineAccont: e.target.value });
                      }}
                      // name="number"
                      label="LINE (เฉพาะกลุ่ม PMS PMG PCC)"
                      value={object.lineAccont}
                      type="text"
                      id="outlined-error-helper-text"
                      disabled={Disline}
                    />
                    {/* ) : null} */}
                  </Grid>
                  ) : null}

                  {/* <Grid item xs={6}>
                  <TextField
                    error={FnameLname === true}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => {
                      e.preventDefault();
                      setFnameLname(e.target.value);
                    }}
                    name="number"
                    label="นามสกุล"
                    type="text"
                    id="outlined-error-helper-text"
                    // helperText="Incorrect entry."
                    autoComplete="lname"
                    helperText={FnameLname === true ? "กรุณาใส่ นามสกุล" : null}
                  />
                </Grid>
              </Grid> */}
                </Grid>
              ) : null}
              {/* <div className={classes.wrapper}>
                {button === true && accept === true ? (
                  <TelegramLoginButton
                    dataOnauth={handleTelegramResponse}
                    botName="karan509_bot"
                  />
                ) : null}
              </div> */}
              {/* {button === true ? (
                <div className={classes.wrapper}>
                  <Waiter>
                    <UserCreateButton />
                  </Waiter>
                  {loading && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              ) : null} */}
              {button === true ? (
                <Grid item xs={12}>
                  <div className={classes.wrapper}>
                    <Waiter>
                      <Grid container spacing={2} columns={12}>
                        <Grid item xs={6}>
                          <UserCreateButton />
                        </Grid>
                        <Grid item xs={6}>
                          <UserCreateButton2 />
                        </Grid>
                      </Grid>
                    </Waiter>
                    {loading && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              ) : null}

              <div className={classes.wrapper}>
                {button === false && success === true ? (
                  <TelegramLoginButton
                    dataOnauth={handleTelegramResponse}
                    botName={botname}
                  />
                ) : null}
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );

  // }
}
