import React from "react";
// import Les1 from "./page/les1";
import { HashRouter as Router, Route } from "react-router-dom";
import RoutIndex from "./page/Rounting";
import RoutIndexEdit from "./page/Rounting_edit";
import AAM from "./page/AAM";
import PGH from "./page/PGH";
import GuestWifi from "./page/GuestWifi";
import LoginJson from "./page/loginjson";
import OTP from "./page/SMSOTP";
import TELEGRAM from "./page/tele_regisadmin";
import TELEGRAM2 from "./page/tele_regisadmin";
import TELEGRAMGROUP from "./page/tele_group";
import TELEGRAMGROUPAICP from "./page/tele_group_aicp";
import TELEGRAMGROUPAICP2 from "./page/tele_group_aicp2";
import Realtime from "./page/realtime";
import wifi_student from "./page/wifi_student";
import wifi_student_approve from "./page/wifi_student_approve";
import tele_regisadmin_pkg from "./page/tele_regisadmin_pkg";
import tele_group_pkg from "./page/tele_group_pkg";
import tele_group_pgh from "./page/tele_group_pgh";
import Pdfencryp from "./page/pdfencryp";
import web3storage from "./page/web3storage"
import AES from "./page/exaes"
import Line_regisgroupCus from "./page/line_AAMregisgroupCus"
import Line_pmsregisgroupCus from "./page/line_PMSregisgroupCus"
import Facebook_Rafco from "./page/Facebook_Rafco"
import Facebook_Rafco_Admin from "./page/Facebook_Rafco_Admin"
import Line_regisadmin from "./page/Line_regisadmin"
import closep from "./page/closep"
import rafcoendjob from "./page/rafcoendjob"
import aicpendjob from "./page/aicpendjob"
import pmsendjob from "./page/pmsendjob"
import PGHchatinapp from "./page/PGHchatinapp"
import Line_regisMR from "./page/Line_regisMR"

//template
import templateendjob from "./page/templateendjob"
import pmsendjobTG from "./page/pmsendjobTG"
import aamendjobTG from "./page/aamendjobTG"
import rplcendjobTG from "./page/rplcendjobTG"


export default function App() {
  return (
    <div style={{ textAlign: "center" }}>
      <Router>
        <div> 
          {/* RountPath Product */}
          <Route exact path="/" component={RoutIndex} />
          <Route path="/aam" component={AAM} />
          <Route path="/pgh" component={PGH} />
          <Route path="/line_reginadmin" component={Line_regisadmin} />
          <Route path="/guestwifi" component={GuestWifi} />
          <Route path="/loginjson" component={LoginJson} />
          <Route path="/pdfencryp" component={Pdfencryp} />
          <Route path="/otp" component={OTP} />
          <Route path="/tele_reg" component={TELEGRAM} />
          <Route path="/tele_group" component={TELEGRAMGROUP} />
          <Route path="/tele_group_aicp" component={TELEGRAMGROUPAICP} />
          <Route path="/tele_group_aicp2" component={TELEGRAMGROUPAICP2} />  
          <Route path="/realtime" component={Realtime} />
          <Route path="/wifi_student" component={wifi_student} />
          <Route
            path="/wifi_student_approve"
            component={wifi_student_approve}
          />
          <Route path="/tele_regisadmin_pkg" component={tele_regisadmin_pkg} />
          <Route path="/tele_group_pkg" component={tele_group_pkg} />
          <Route path="/tele_group_pgh" component={tele_group_pgh} />
          <Route path="/web3storage" component={web3storage} />
          <Route path="/aamLine" component={Line_regisgroupCus} />
          <Route path="/pmsLine" component={Line_pmsregisgroupCus} />
          <Route path="/Facebook_Rafco" component={Facebook_Rafco} />
          <Route path="/Facebook_Rafco_Admin" component={Facebook_Rafco_Admin} />
          <Route path="/Facebook_AICP" component={Facebook_Rafco} />
          <Route path="/Facebook_AICP_Admin" component={Facebook_Rafco_Admin} />
          <Route path="/closep" component={closep} />
          <Route path="/rafcoendjob" component={rafcoendjob} />
          <Route path="/aicpendjob" component={aicpendjob} />
          <Route path="/pmsendjob" component={pmsendjob} />
          <Route path="/PGHchatinapp" component={PGHchatinapp} />
          <Route path="/Line_regisMR" component={Line_regisMR} />
          <Route path="/templateendjob" component={templateendjob} />
          <Route path="/pmsendjobTG" component={pmsendjobTG} />
          <Route path="/aamendjobTG" component={aamendjobTG} />
          <Route path="/rplcendjobTG" component={rplcendjobTG} />
          

          {/* RountPath Edit */}
          {/* <Route path="/edit" component={RoutIndexEdit} />
          <Route path="/edit/tele_reg" component={TELEGRAM2} />
          <Route path="/edit/tele_group" component={TELEGRAMGROUP} /> */}
          <Route path="/aes" component={AES} />
        </div>
      </Router>
    </div>
  );
}
